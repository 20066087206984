<template lang="">
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.375 14H6.25V13.375C6.25 13.0312 5.96875 12.75 5.625 12.75H4.375C4.03125 12.75 3.75 13.0312 3.75 13.375V14H0.625C0.28125 14 0 14.2812 0 14.625V15.875C0 16.2188 0.28125 16.5 0.625 16.5H3.75V17.125C3.75 17.4688 4.03125 17.75 4.375 17.75H5.625C5.96875 17.75 6.25 17.4688 6.25 17.125V16.5H19.375C19.7188 16.5 20 16.2188 20 15.875V14.625C20 14.2812 19.7188 14 19.375 14ZM19.375 7.75H16.25V7.125C16.25 6.78125 15.9688 6.5 15.625 6.5H14.375C14.0312 6.5 13.75 6.78125 13.75 7.125V7.75H0.625C0.28125 7.75 0 8.03125 0 8.375V9.625C0 9.96875 0.28125 10.25 0.625 10.25H13.75V10.875C13.75 11.2188 14.0312 11.5 14.375 11.5H15.625C15.9688 11.5 16.25 11.2188 16.25 10.875V10.25H19.375C19.7188 10.25 20 9.96875 20 9.625V8.375C20 8.03125 19.7188 7.75 19.375 7.75ZM19.375 1.5H11.25V0.875C11.25 0.53125 10.9688 0.25 10.625 0.25H9.375C9.03125 0.25 8.75 0.53125 8.75 0.875V1.5H0.625C0.28125 1.5 0 1.78125 0 2.125V3.375C0 3.71875 0.28125 4 0.625 4H8.75V4.625C8.75 4.96875 9.03125 5.25 9.375 5.25H10.625C10.9688 5.25 11.25 4.96875 11.25 4.625V4H19.375C19.7188 4 20 3.71875 20 3.375V2.125C20 1.78125 19.7188 1.5 19.375 1.5Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        
    }
</script>
