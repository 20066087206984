<template>
    <div class="map-block">
        <p class="map-block__title">{{ title }}</p>
        <img class="map-block__body" src="../assets/img/map.png" alt="map">
    </div>
</template>
<script>
    export default {
        name: "MapComponent",
        props: {
            title: String
        }
    }
</script>
<style lang="scss" scoped>
    .map-block{
        padding: 0 20px;
        margin-bottom: 78px;
        &__title{
            margin-bottom: 42px;
            font-size: 36px;
            font-weight: 600;
        }

        &__body{
            width: 100%;
        }
    }
</style>