<template>
    <div class="room-characteristics">
        
        <p class="room-characteristics__title">{{ title }}</p>
        
        <div 
            class="room-characteristics__list"
            :style="styleObject"
        >
            <div class="room-characteristics__item" v-for="(item,index) in data" :key="index">
                <p class="room-characteristics__item-label">{{ item.label }}</p>
                <p 
                    class="room-characteristics__item-data" 
                    :class="{'room-characteristics__item-data_dashed': item.dashed}"
                >
                    {{ item.data }}
                </p>
            </div>
        </div>

        <div class="room-characteristics__info-location">
            <div class="room-characteristics__location-header">
                <div class="room-characteristics__logo-location">
                    <GreenPointSvg/>
                </div>
                <p class="room-characteristics__location">
                    Москва, проспект Магеллана, ЖК “Саларьево парк”, 49, секция 6, корпус 2
                </p>
            </div>
            <div class="room-characteristics__info-metro">
                <div class="room-characteristics__dots" />
                <div class="room-characteristics__list-metro">
                    <p><MetroSvg class="room-characteristics__svg-metro"/>Филатов луг <span class="room-characteristics__travel-time">5 минут</span></p>
                    <p><MetroSvg theme="orange" class="room-characteristics__svg-metro"/>Прокшино <span class="room-characteristics__travel-time">10 минут</span></p>
                    <p><MetroSvg theme="yellow" class="room-characteristics__svg-metro"/>Новые Чёрёмушки <span class="room-characteristics__travel-time">10 минут</span></p>
                    <p><MetroSvg theme="red" class="room-characteristics__svg-metro"/>Филатов луг <span class="room-characteristics__travel-time">5 минут</span></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import MetroSvg from "@/components/svg/MetroSvg.vue" 
    import GreenPointSvg from "@/components/svg/GreenPointSvg.vue" 

    export default {
        name: "RoomCharacteristics",
        components: {
            MetroSvg,
            GreenPointSvg
        },
        props: {
            data: {
                type: Array,
            },
            title: String,
            columns: String
        },
        data(){
            return {
                styleObject: {
                    gridTemplateColumns: this.columns
                } 
            }
        }
    }

</script>

<style lang="scss">
    .room-characteristics {
        display: flex;
        flex-direction: column;
        padding: 0 22px;

        &__title {
            line-height: 51px;
            font-weight: 600;
            letter-spacing: 0.01em;
            font-size: 44px;
            color: #112233;
            margin-bottom: 24px;

            @media all and (max-width: 768px) {
                font-size: 28px;
                line-height: 100%;
            }
        }
        
        &__list {
            display: grid;
            grid-template-columns: repeat(4,1fr);
            gap: 13px;

            @media all and (max-width: 768px) {
                margin-bottom: 24px;
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            width: max-content;
            gap: 11px;
        }

        &__item-label {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.01em;
            color: rgba(17, 34, 51, 0.35);
        }

        &__item-data {
            font-size: 18px;
            line-height: 140%;
            letter-spacing: 0.01em;
            &_dashed {
                border-bottom: 1px dashed #009B47;
            }
        }

        &__info-location {
            margin-top: 62px;
            @media all and (max-width: 768px) {
                display: none;
            }
        }

        &__location-header {
            display: flex;
            gap: 28px;
            align-items: center;
        }

        &__logo-location {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #E5F5ED;
            border-radius: 10px;
            width: 40px;
            height: 40px;
        }

        &__location {
            color: #112233;
            font-size: 18px;
        }

        &__info-metro {
            display: flex;
            gap: 28px;
        }

        &__list-metro {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            font-size: 16px;
            row-gap: 18px;
            column-gap: 24px;
            color: #112233;
        }

        &__travel-time {
            color: #ACB2B8;
            margin-left: 9px;
        }

        &__dots {
            position: relative;
            width: 40px;
            
            &::before {
                top: 7px;
                position: absolute;
                width: 100%;
                left: 50%;
                letter-spacing: 1px;
                height: 12px;
                content: "\00B7 \00B7 \00B7 \00B7 \00B7 \00B7";
                font-size: 32px;
                // writing-mode: tb;
                transform: translate(-35%, 100%) rotate(90deg);
                color:#009B47;
            }
        }

        &__svg-metro{
            margin-right: 7px;
        }
    }
</style>
