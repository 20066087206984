<template>
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 0.5H9C8.45 0.5 8 0.95 8 1.5V3.11C8 3.11 8 3.11 8.01 3.12L13.01 7.62C13.64 8.18 14 9 14 9.85V10.5H16V12.5H14V14.5H16V16.5H14V18.5H17C17.55 18.5 18 18.05 18 17.5V1.5C18 0.95 17.55 0.5 17 0.5ZM12 4.5H10V2.5H12V4.5ZM16 8.5H14V6.5H16V8.5ZM16 4.5H14V2.5H16V4.5Z" fill="#173B5E" fill-opacity="0.35"/>
        <path d="M12 17.5V9.84998C12 9.56998 11.88 9.29998 11.67 9.10998L6.67 4.60998C6.48 4.42998 6.24 4.34998 6 4.34998C5.76 4.34998 5.52 4.43998 5.33 4.60998L0.33 9.10998C0.12 9.28998 0 9.55998 0 9.84998V17.5C0 18.05 0.45 18.5 1 18.5H3C3.55 18.5 4 18.05 4 17.5V13.5H8V17.5C8 18.05 8.45 18.5 9 18.5H11C11.55 18.5 12 18.05 12 17.5Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>
<script>
    export default {
        name: "HousesSvg"
    }
</script>
