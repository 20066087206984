<template lang="">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3 0.71C12.91 0.32 12.28 0.32 11.89 0.71L6.99997 5.59L2.10997 0.7C1.71997 0.31 1.08997 0.31 0.699971 0.7C0.309971 1.09 0.309971 1.72 0.699971 2.11L5.58997 7L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.41L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 7L13.3 2.11C13.68 1.73 13.68 1.09 13.3 0.71Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>