<template>
    <MainContainer>
      <ContentLayout white containerStyle='padding: 0 13px'>
      <div class="all-apartments__direction">
        <DirectionLine :steps="['Недвижимость', 'Ипотека', 'Рефинансирование']"/>
      </div>
      <div class="all-apartments__selection">
        <BuildingsSelection title="Купить квартиру"/>
      </div>
      </ContentLayout>
      <ContentLayout>
          <ApartmentsList/>
      </ContentLayout>
    </MainContainer>
  </template>
  
  <script>
      import MainContainer from '@/layouts/MainContainer.vue';
      import BuildingsSelection from '@/layouts/BuildingsSelection.vue';
      import ContentLayout from '@/layouts/ContentLayout.vue';
      import ApartmentsList from '@/components/ApartmentsList.vue';
      import DirectionLine from '@/components/DirectionLine.vue';
  
      export default {
          name: "ApartmentsPage",
          components: {
            MainContainer,
            BuildingsSelection,
            ContentLayout,
            ApartmentsList,
            DirectionLine
          },
      }
  </script>
  
  <style lang="scss">
    .all-apartments{

      &__direction{
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      
    }
    
  </style>