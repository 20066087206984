<template>
    <div class="general-description">
        <div class="general-description__header">
            <Heading 
                title="Общее описание" 
                :toggleList="true"
                :options="[
                    {
                        text: 'О квартире',
                        selected: true
                    },
                    {
                        text: 'Характеристики',
                        selected: false
                    }
                ]"
            >
                <div class="general-description__description">
                    <Description :unwrapText="false">
                        Жилой район «Саларьево парк» расположен в Новой Москве, в 6 километрах от МКАД по Киевскому шоссе. В пяти минутах пешком находится станция метро «Филатов Луг».  ТРЦ «Саларис» и крупные торговые центры — «РИО», Metro Cash & Carry, «Леруа Мерлен» — всего в пяти минутах езды на машине. 
                    </Description>
                </div>
            </Heading>
        </div>
    </div>
</template>
<script>
    import Heading from "./Heading.vue"
    import Description from "./Description.vue"

    export default {
        components: {
            Heading,
            Description
        }
    }
</script>
<style lang="scss">
    .general-description{
        padding: 0 20px;
        max-width: 800px;

        &__header{
        }

        &__main{
            display: flex;
            flex-direction: column;
            gap: 37px;
            margin-bottom: 74px;

            @media all and (max-width: 1024px) {
                display: none;
            }
        }

    }
</style>