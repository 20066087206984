<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="black" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M15.7157 1.70553C13.9319 0.185376 11.2789 0.458808 9.64159 2.14823L9.00032 2.80902L8.35906 2.14823C6.72497 0.458808 4.06877 0.185376 2.28496 1.70553C0.240723 3.45029 0.133303 6.58174 1.9627 8.47298L8.2614 14.9768C8.6683 15.3967 9.32909 15.3967 9.73598 14.9768L16.0347 8.47298C17.8673 6.58174 17.7599 3.45029 15.7157 1.70553Z" 
            :fill='currentTheme' 
            fill-opacity="0.65"
        />
    </svg>
</template>

<script>

    const themes = {
        default: "#143352",
        light: "#2A547E21"
    }

    export default {
        name:  "HeartSvg",
        props: {
            theme: {
                type: String,
                required: false,
                default: "default"
            }
        },
        data(){
            return {
                currentTheme: themes[this.theme]
            }
        },
    
    }
</script>

<style lang="">
    
</style>