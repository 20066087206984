<template>
    <div class="drop-list">
        <div class="drop-list__show">
            <p class="drop-list__title">{{ title }}</p>
            <ArrowRight class="drop-list__arrow" theme="green" opacity="1"/>
        </div>
        <slot />
    </div>
</template>

<script>
    import ArrowRight from "@/components/svg/ArrowRightSvg.vue"

    export default {
        name: "DropList",
        props: {
            title: {
                type: String,
                default: "Показать все фильтры"
            }
        },
        components: {
            ArrowRight
        }
    }
</script>

<style lang="scss">
    .drop-list {
        &__title {
            cursor: pointer;
            color: #009B47;
            font-size: 16px;
        }

        &__show{
            display: flex;
            align-items: center;
            gap: 6px;
        }
        
        &__arrow{
            width: 12px;
            height: 8px;
            transform: rotate(90deg) translateX(-1px);
        }
    }
</style>