<template>
    <div class="nearest-location">
        <div class="nearest-location__container">
            <p class="nearest-location__title">Ближайшие жилые комплексы</p>
            <div class="nearest-location__wrapper">
                <NearestResComplexCard />
                <NearestResComplexCard />
                <NearestResComplexCard />
                <NearestResComplexCard />
                <NearestResComplexCard />
            </div>
        </div>
    </div>
</template>

<script>
    import NearestResComplexCard from '@/components/NearestResComplexCard.vue';
    
    export default {
        name: "NearestResComplexList",
        components: {
            NearestResComplexCard,
        }
    }
</script>


<style lang="scss" scoped>
    @import "@/style/variables";
    .nearest-location{
        background-color: $background-primary;
        overflow: hidden;
        padding: 46px 0 90px 20px;

        &__container{
            margin: 0 auto 0 auto;
            max-width: 1200px;
        }

        &__title{
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 49px;

            @media all and (max-width: 768px) {
                font-size: 24px;
            }
        }

        &__wrapper{
            display: flex;
            gap: 23px;

            @media all and (max-width: 768px) {
                gap: 12px;
            }
        }
    }
</style>