<template>
  <button
        :class="rootClasses"
    >
    <slot name="svg"/>
    <slot />
  </button>
</template>

<script>

    export default {
        name: "ButtonCustom",
        props: {
            themes: String,
            noPadding: Boolean
        },
        data() {

            const themes = {
                "dark": "button_dark",
                "noPadding": "button_no-padding",
                text: "button_text",
                green: "button_green",
                white: "button_white",
                svg: "button_svg",
                small: "button_small",
            } 

            const rootClasses = ["button"]
            this.themes && this.themes.split(" ").forEach(theme => rootClasses.push(themes[theme]))

            return {
                isActive: true,
                rootClasses
            }
        },
    }
</script>

<style lang="scss">
    @import "@/style/variables.scss";

    .button {
        color: #FFFFFF;
        padding: 11px 44px;
        background: #009B47;
        border-radius: 13px;
        font-weight: 500;
        font-size: 16px;
        position: relative;

        &_svg{
            display: flex;
            align-items: center;
            gap: 9px;
            padding-left: 15px;
            padding-right: 15px;
            padding: 15px;
        }

        &_dark {
            background: $background-primary;
            color: $text-primary;
        }
        &_white{
            background-color: #fff;
            color: $text-primary;
            box-shadow: 0px 7px 15px -2px rgba(0, 0, 0, 0.05);
        }
        &_green{
            color: $text-positive;
        }
        &_no-padding{
            padding: 11px 8px;
            white-space: nowrap;
        }
        &_text {
            color: #173B5E59 !important;
            background: transparent !important;
            padding: 0 !important;
        }
        &_small{
            padding: 10px 20px;
            white-space: nowrap;
        }
    }
</style>