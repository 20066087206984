<template>
    <div class="card-house">
        <div class="card-house__header">
            <div class="card-house__logo-container">
                <div class="card-house__logo">
                    <img src="@/assets/img/gg_logo.png" alt="builder-logo">
                </div>
                <div class="card-house__developer">
                    <p class="card-house__developer-name">ГК Гранель</p>
                    <p class="card-house__developer-objects">234 объекта</p>
                </div>
            </div>
            <img
                class="card-house__avatar"
                src="@/assets/img/house_4.png"
                alt="avatar"
            />
            <SliderSwitch/>
        </div>
        <div class="card-house__content">
            
            <div class="card-house__header-content">
                <div class="card-house__title">
                    ЖК Новая Рига
                </div>
                <HeartSvg theme="light"/>
            </div>

            <div class="card-house__location">
                <p class="card-house__region">Рассказовка</p>
                <div class="card-house__time"><ManSvg/><p>10 мин</p></div>
            </div>
            

            <div class="card-house__tag-list">
                <TagComponents>Скидка 10%</TagComponents>
                <TagComponents>0Р оформление</TagComponents>
                <TagComponents>С отделкой</TagComponents>
            </div>

            <div class="card-house__variants">
                <div class="card-house__room-type">Студии</div>
                <div class="card-house__room-sqaure">от 19.23м2</div>
                <div>от 4.6 млн. р</div>
                <div class="card-house__room-type">1 комн.</div>
                <div class="card-house__room-sqaure">от 19.23м2</div>
                <div>от 5.6 млн. р</div>
                <div class="card-house__room-type">2 комн.</div>
                <div class="card-house__room-sqaure">от 19.23м2</div>
                <div>от 6.3 млн. р</div>
                <div class="card-house__room-type">3+ комн.</div>
                <div class="card-house__room-sqaure">от 19.23м2</div>
                <div>от 6.3 млн. р</div>
            </div>

            <div class="card-house__buttons">
                <Button themes="noPadding">Позвонить</Button>
                <Button themes="dark noPadding">Заказать звонок</Button>
            </div>
        </div>
    </div>
</template>

<script>
    import TagComponents from "./Tag.vue"
    import HeartSvg from "./svg/HeartSvg.vue"
    import ManSvg from "./svg/ManSvg.vue"
    import Button from "./Button.vue"
    import SliderSwitch from "@/components/SliderSwitch.vue"

    export default {
        name:  "CardHouse",
        props: {

        },
        components: {
            TagComponents,
            HeartSvg,
            Button,
            ManSvg,
            SliderSwitch
        }
    }
</script>

<style lang="scss">

    @import "@/style/variables.scss";

    .card-house {
        border-radius: 20px;
        overflow: hidden;
        background-color: #FFFFFF;

        &__header {
            position: relative;
            border-radius: 0 0 11.7px 11.7px;
            overflow: hidden;
        }

        &__avatar {
            height: 272px;
            width: 100%;
            object-fit: cover;
        }

        &__logo-container{
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 13px;
            left: 14px;
            background-color: rgba(0, 0, 0, 0.3);
            backdrop-filter: blur(12px);
            border-radius: 40px;
            padding: 4px 24px 4px 5px;
            gap: 7px;

            @media all and (min-width: 768px) {
                padding: 4px 5px;
            }

        }

        &__logo {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #DDE4EA;
            width: 32px;
            height: 32px;
            background-color: #FFFFFF;
            border-radius: 50%;
        }

        &__developer{
            @media all and (min-width: 768px) {
                display: none;
            }
        }

        &__developer-name, &__developer-objects{
            text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
            letter-spacing: 0.01em;
            color: #FFFFFF;
            font-size: 14px;
        }

        &__developer-name{
            font-weight: 500;
            line-height: 20px;
        }

        &__developer-objects{
            opacity: 0.5;
        }

        &__content {
            padding: 19px 18px 29px;

            @media all and (max-width: 768px) {
                padding-bottom: 20px;
            }
        }

        &__header-content {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }
        
        &__title {
            color: #112132;
            font-weight: 500;
            font-size: 21px;
            letter-spacing: 0.01em;
            white-space: nowrap;
        }

        &__time{
            display: flex;
        }

        &__location {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 9px;
            color: $text-secondary;
        }

        &__tag-list {
            display: flex;
            gap: 7px;
            flex-wrap: wrap;
        }

        &__variants {
            display: grid;
            gap: 14px;
            grid-template-columns: repeat(3,1fr);
            font-size: 16px;
            font-weight: 400;
            color: #112132;
            margin-top: 20px;
            white-space: nowrap;
        }

        &__room-type {
            color: #3C7EFF;
        }
        &__room-sqaure {
            color: rgba(20, 51, 82, 0.65);
        }

        &__buttons{
            justify-content: space-between;
            gap: 10px;
            margin-top: 14px;

            & > *{
                flex: 1
            }

            @media all and (min-width: 768px) {
                display: none;

            }
            @media all and (max-width: 768px) {
                display: flex;

            }
        }
    }
</style>