<template>
    <div class="selection">
        <div class="selection__container">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: "SelectionLayout",
        props: {

        }
    }
</script>

<style lang="scss">
    .selection {
        margin: 0 auto;
        max-width: 1200px;

        &__container{
            // padding: 0 20px;

            @media all and (max-width: 768px) {
                // padding: 0 13px;
            }
        }

        
    }
</style>