<template >
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 16.332V18C0 19.3789 3.35938 20.5 7.5 20.5C11.6406 20.5 15 19.3789 15 18V16.332C13.3867 17.4688 10.4375 18 7.5 18C4.5625 18 1.61328 17.4688 0 16.332ZM12.5 5.5C16.6406 5.5 20 4.37891 20 3C20 1.62109 16.6406 0.5 12.5 0.5C8.35938 0.5 5 1.62109 5 3C5 4.37891 8.35938 5.5 12.5 5.5ZM0 12.2344V14.25C0 15.6289 3.35938 16.75 7.5 16.75C11.6406 16.75 15 15.6289 15 14.25V12.2344C13.3867 13.5625 10.4336 14.25 7.5 14.25C4.56641 14.25 1.61328 13.5625 0 12.2344ZM16.25 12.6641C18.4883 12.2305 20 11.4258 20 10.5V8.83203C19.0938 9.47266 17.7617 9.91016 16.25 10.1797V12.6641ZM7.5 6.75C3.35938 6.75 0 8.14844 0 9.875C0 11.6016 3.35938 13 7.5 13C11.6406 13 15 11.6016 15 9.875C15 8.14844 11.6406 6.75 7.5 6.75ZM16.0664 8.94922C18.4102 8.52734 20 7.69922 20 6.75V5.08203C18.6133 6.0625 16.2305 6.58984 13.7227 6.71484C14.875 7.27344 15.7227 8.02344 16.0664 8.94922Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>
<script>
    export default {
        name: "CoinsSvg"
    }
</script>
