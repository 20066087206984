<template>
    <div class="footer">
        <div class="footer__top">
            <div class="footer__top-container footer__container">
                <div class="footer__subscribe-info">
                    <h3 class="footer__subscribe-title">Будьте в курсе</h3>
                    <p class="footer__subscribe-text">
                        Подпишитесь на рассылку ПоВоенке, чтобы получать выгодные предложения первыми
                    </p>
                </div>
                <div class="footer__subscribe-form">
                    <Input placeholder="Введите email"/>
                    <Button>
                        Подписаться
                    </Button>
                </div>
            </div>
        </div>
        <div class="footer__bottom">
            <div class="footer__bottom-container footer__container">
                <div >
                    <img class="footer__logo" src="@/assets/logo_gray.png" alt="Логотип">
                    <p class="footer__contacts-phone">
                        +7 912 992 53 84
                    </p>
                    <p class="footer__contacts-time">
                        Пн-Пт 09:00-18:00
                    </p>
                </div>
                <div class="footer__nav">
                    <div class="footer__nav-block">
                        <div class="footer__nav-item">
                            <p class="footer__nav-title">
                                Купить
                            </p>
                            <ul class="footer__nav-list">
                                <li class="footer__nav-list-item">Каталог новостроек</li>
                                <li class="footer__nav-list-item">Квартиры в Новостройках</li>
                                <li class="footer__nav-list-item">Вторичное жилье</li>
                                <li class="footer__nav-list-item">Застройщики</li>
                            </ul>
                        </div>
                        <div class="footer__nav-item">
                            <p class="footer__nav-title">
                                Ипотека
                            </p>
                            <ul class="footer__nav-list">
                                <li class="footer__nav-list-item">Предложения банков</li>
                                <li class="footer__nav-list-item">Рефинансирование</li>
                            </ul>
                        </div>
                    </div>
                    <div class="footer__nav-block">
                        <div class="footer__nav-item">
                            <p class="footer__nav-title">
                                Калькуляторы
                            </p>
                            <ul class="footer__nav-list">
                                <li class="footer__nav-list-item">Сумма накоплений</li>
                                <li class="footer__nav-list-item">Сумма ипотеки</li>
                                <li class="footer__nav-list-item">Выгода от рефинансирования</li>
                            </ul>
                        </div>
                        <div class="footer__nav-item">
                            <p class="footer__nav-title">
                                Военнослужащим
                            </p>
                            <ul class="footer__nav-list">
                                <li class="footer__nav-list-item">База знаний</li>
                                <li class="footer__nav-list-item">Вопросы и ответы</li>
                                <li class="footer__nav-list-item">Акции</li>
                            </ul>
                        </div>
                    </div>
                    <div class="footer__nav-block">
                        <div class="footer__nav-item">
                            <p class="footer__nav-title">
                                О компании
                            </p>
                            <ul class="footer__nav-list">
                                <li class="footer__nav-list-item">Купить</li>
                                <li class="footer__nav-list-item">О нас</li>
                                <li class="footer__nav-list-item">Новости</li>
                                <li class="footer__nav-list-item">Наша команда</li>
                                <li class="footer__nav-list-item">Отзывы</li>
                                <li class="footer__nav-list-item">Гарантии</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="footer__socials">
                        <p class="footer__socials-title">Соцсети</p>
                        <div class="footer__socials-links">
                            <div class="footer__socials-block">
                                <TelegramSvg/>
                            </div>
                            <div class="footer__socials-block">
                                <VkSvg/>
                            </div>
                            <div class="footer__socials-block">
                                <WhatsappSvg/>
                            </div>
                        </div>
                    </div>
                    <p class="footer__legal">
                        © 2022 Общество с ограниченной ответственностью «поВоенке» Москва, Хорошёвское шоссе, 38к1, 5 этаж, офис 521, ОГРН 5147746388543 ИНН 7725849789. Политика конфиденциальности.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Input from "./Input.vue"
    import Button from "./Button.vue"
    import TelegramSvg from "./svg/TelegramSvg.vue"
    import VkSvg from "./svg/VkSvg.vue"
    import WhatsappSvg from "./svg/WhatsappSvg.vue"

    export default {
        name: "FooterComponent",
        components: {
            Input,
            Button,
            TelegramSvg,
            VkSvg,
            WhatsappSvg
        }
    }
</script>

<style lang="scss">

    @import "../style/_variables.scss";

    .footer{

        &__container{
            margin: 0 auto;
            max-width: 1200px;
            padding: 26px 32px;
        }

        &__top{
            border-bottom: 1px solid $border-primary;
        }

        &__top-container{
            display: flex;
            gap: 19px;

            @media all and (min-width: 768px) {
                justify-content: space-between;
            }

            @media all and (max-width: 1024px) {
                flex-direction: column;
                grid-template-columns: 1fr max-content;
            }

            
        }

        &__subscribe-info{}

        &__subscribe-title{
            font-size: 24px;
            color: $text-primary;
            margin-bottom: 10px;
        }
        &__subscribe-text{
            font-size: 16px;
            color: $text-secondary;

            @media all and (max-width: 375px) {
                max-width: 241px;
            }
        }
        &__subscribe-form{
            display: grid;
            grid-template-columns: 1fr max-content;
            gap: 12px;

            @media all and (max-width: 768px) {
                grid-template-columns: 1fr;
            }
        }

        &__bottom-container{
            display: grid;
            gap: 18px 90px;
            grid-template-areas: 
                        "contacts nav"
                        "social nav";
            grid-template-rows: min-content 1fr;

            @media all and (max-width: 867px) {
                gap: 18px 40px;
            }

            @media all and (min-width: 768px) {
                grid-template-columns: 278px 1fr;
            }

            @media all and (max-width: 768px) {
                gap: 32px;
                grid-template-areas: 
                        "contacts"
                        "nav"
                        "social";
            }
        }

        &__logo{
            width: 176px;
            margin-bottom: 20px;
        }

        &__contacts-time{
            color: $text-secondary;
            font-size: 14px;
            font-weight: 500;

        }

        &__contacts-phone{
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 4px;
            font-weight: 500;

            @media screen and (max-width: 768px){
                color: $text-positive;
            }
        }

        &__nav{
            display: flex;
            justify-content: space-between;
            column-gap: 40px;
            grid-area: nav;

            @media all and (max-width: 768px) {
                flex-direction: column;
                gap: 35px;
            }
        }

        &__nav-item:not(:last-child) {
            margin-bottom: 35px;

            @media all and (max-width: 768px) {
                flex-direction: column;
            }
        }

        &__nav-title{
            font-size: 18px;
            color: $text-primary;
            margin-bottom: 15px;
            font-weight: 500;
        }

        &__nav-list-item{
            font-size: 16px;
            color: $text-secondary;
            cursor: pointer;
        }

        &__nav-list-item:not(:last-child){
            margin-bottom: 15px;
        }

        &__socials{
            margin-bottom: 43px;
        }

        &__socials-title{
            font-size: 18px;
            color: $text-primary;
            margin-bottom: 15px;
            font-weight: 500;
        }

        &__socials-links{
            display: flex;
            gap: 10px;
        }

        &__socials-block{
            background-color: $background-primary;
            border-radius: 9px;
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        &__legal{
            font-size: 16px;
            color: $text-secondary;
        }
    }
</style>