<template>
  <div 
        class="flex"
        :class="{flex_flexible: flexible}"
        :style="styleObject"
    >
    <slot/>
  </div>
</template>

<script>
    export default {
        name: "FlexLayout",
        props: {
            flexDirection: {
                type: String,
                default: "row"
            },
            gap: Number,
            justifyContent: String,
            alignItems: String,
            flexWrap: String,
            flexible: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                styleObject: {
                    flexDirection: this.flexDirection,
                    justifyContent: this.justifyContent,
                    alignItems: this.alignItems,
                    flexWrap: this.flexWrap,
                    gap: `${this.gap}px`,
                    flexible: this.flexible
                },
            };
        },
    }
</script>

<style lang="scss">
    .flex {
        display: flex;
        flex-wrap: wrap;

        &_flexible > * {
            flex: 1
        }
    }
</style>