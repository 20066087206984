<template>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.25 11.75H4.0625C3.88984 11.75 3.75 11.6102 3.75 11.4375V10.8125C3.75 10.6398 3.88984 10.5 4.0625 10.5H6.25V8H4.0625C3.88984 8 3.75 7.86016 3.75 7.6875V7.0625C3.75 6.88984 3.88984 6.75 4.0625 6.75H6.25V4.25H4.0625C3.88984 4.25 3.75 4.11016 3.75 3.9375V3.3125C3.75 3.13984 3.88984 3 4.0625 3H6.25V1.75C6.25 1.05977 5.69023 0.5 5 0.5H1.25C0.559766 0.5 0 1.05977 0 1.75V19.25C0 19.3582 0.0355469 19.4547 0.0613281 19.5547L6.25 13.3664V11.75ZM18.75 14.25H17.5V16.4375C17.5 16.6102 17.3602 16.75 17.1875 16.75H16.5625C16.3898 16.75 16.25 16.6102 16.25 16.4375V14.25H13.75V16.4375C13.75 16.6102 13.6102 16.75 13.4375 16.75H12.8125C12.6398 16.75 12.5 16.6102 12.5 16.4375V14.25H10V16.4375C10 16.6102 9.86016 16.75 9.6875 16.75H9.0625C8.88984 16.75 8.75 16.6102 8.75 16.4375V14.25H7.13398L0.945312 20.4387C1.04531 20.4645 1.14219 20.5 1.25 20.5H18.75C19.4402 20.5 20 19.9402 20 19.25V15.5C20 14.8098 19.4402 14.25 18.75 14.25Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>
<script>
    export default {
        name: "RulersSvg"
    }
</script>
