<template>
    <div class="banner">
        <div class="banner__wrapper">
            <div class="banner__top">
                <slot/>
            </div>
            <div class="banner__container">
                <div>
                    <p class="banner__title">
                        Покупайте дешевле, чем у застройщика
                    </p>
                    <p class="banner__sub-title">
                        При оформлении через Повоенке скидка 1% к любой стоимости
                    </p>
                </div>
                <img
                    class="banner__image"
                    :src="bannerIcon"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import bannerIcon from "@/assets/img/banner_icon.png"

    export default {
        name: "BannerComponents",
        props: {

        },
        data: function () {
            return {
                bannerIcon
            }
        },
        mounted() {
            console.log(this.$slots.default);

        }
    }

</script>

<style lang="scss">
    
    .banner {
        // display: flex;
        // align-items: center;
        background: 
            linear-gradient(256.48deg, rgba(0, 123, 86, 0.2) 43.51%,
            rgba(213, 179, 0, 0.2) 66.51%),
            linear-gradient(110.51deg, #E5F5ED -10.67%, #FFFFFF -10.67%,
            rgba(0, 140, 70, 0) 64.72%);
        // min-height: 390px;
        padding: 35px 0;
        position: relative;

        &__wrapper{
            height: 100%;
            padding: 35px 0;
            margin: 0 auto;
            padding: 0px 20px;
            max-width: 1200px;
        }

        &__container{
            // padding: 0 170px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto;
            max-width: 1200px;

            @media all and (max-width: 768px) {
                flex-direction: column-reverse;
                padding-bottom: 44px;
                padding-top: 44px;
            }
        }

        &__top{
            position: absolute;
            top: 0;
        }
        
        &__title {
            color: #112132;
            font-weight: 600;
            font-size: 44px;
            max-width: 450px;
        }

        &__sub-title {
            color: #143352A6;
            line-height: 140%;
            font-weight: 400;
            font-size: 18px;
            max-width: 400px;
        }

        &__image{

            @media all and (max-width: 768px) {
                width: 100%;
            }
        }

        
    }

</style>