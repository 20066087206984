<template>
    <div 
        class="grid"
        :style="styleObject"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        name: "GridLayout",
        props: {
            gridTemplateColumns: String,
            gridTemplateRows: String,
            gridAutoFlow: String,
            gridAutoColumns: String,
            gap: Number
        },
        data() {
            return {
                styleObject: {
                    gridTemplateColumns: this.gridTemplateColumns,
                    gridTemplateRows: this.gridTemplateRows,
                    gridAutoColumns: this.gridAutoColumns,
                    gridAutoFlow: this.gridAutoFlow,
                    gap: `${this.gap}px`
                },
            };
        },
    }
</script>

<style>
    .grid {
        display: grid;
    }
</style>