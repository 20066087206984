<template>
    <div class="about-house">
        <div class="about-house__header">
            <Heading 
                title="О комплексе" 
                :toggleList="true"
                :options="[
                        {
                            text: 'Описание',
                            selected: true
                        },
                        {
                            text: 'Подробные характеристики',
                            selected: false
                        }
                    ]"
            >
                <div class="about-house__description">
                    <Description :unwrapText="true">
                        Жилой район «Саларьево парк» расположен в Новой Москве, в 6 километрах от МКАД по Киевскому шоссе. В пяти минутах пешком находится станция метро «Филатов Луг».  ТРЦ «Саларис» и крупные торговые центры — «РИО», Metro Cash & Carry, «Леруа Мерлен» — всего в пяти минутах езды на машине. 
                    </Description>
                </div>
            </Heading>
        </div>
        <div class="about-house__main">
            <Heading title="Экология" mini>
                <div class="about-house__description">
                    <Description :unwrapText="true">
                        Жилой район «Саларьево парк» расположен в Новой Москве, в 6 километрах от МКАД по Киевскому шоссе. В пяти минутах пешком находится станция метро «Филатов Луг».  ТРЦ «Саларис» и крупные торговые центры — «РИО», Metro Cash & Carry, «Леруа Мерлен» — всего в пяти минутах езды на машине. 
                    </Description>
                </div>
            </Heading>
            <Heading title="Урбанистика" mini>
                <div class="about-house__description">
                    <Description :unwrapText="true">
                        Жилой район «Саларьево парк» расположен в Новой Москве, в 6 километрах от МКАД по Киевскому шоссе. В пяти минутах пешком находится станция метро «Филатов Луг».  ТРЦ «Саларис» и крупные торговые центры — «РИО», Metro Cash & Carry, «Леруа Мерлен» — всего в пяти минутах езды на машине. 
                    </Description>
                </div>
            </Heading>
        </div>
    </div>
</template>

<script>
    import Heading from "./Heading.vue"
    import Description from "./Description.vue"

    export default {
        components: {
            Heading,
            Description
        }
    }
</script>

<style lang="scss">
    .about-house{
        padding: 0 20px;
        max-width: 667px;

        &__header{
            margin-bottom: 55px;
        }

        &__main{
            display: flex;
            flex-direction: column;
            gap: 37px;
            margin-bottom: 74px;

            @media all and (max-width: 1024px) {
                display: none;
            }
        }
    }
</style>