<template>
    <div>
        <Header/>
        <slot />
        <Footer/>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue'

    export default {
        name: "MainContainer",
        components: {
            Header,
            Footer
        },
        props: {
            Header,
        }
    }
</script>

<style>
 
</style>