<template>
  <MainContainer>
    <ContentLayout white containerStyle='padding: 0 13px'>
      <div class="apartments-page__direction">
        <DirectionLine :steps="['Недвижимость', 'Ипотека', 'Рефинансирование']"/>
      </div>
      <BuildingsSelection title="72 квартиры в ЖК Новая Рига"/>
    </ContentLayout>
    <ContentLayout style="padding: 0 5px;">
        <ApartmentsList/>
    </ContentLayout>
  </MainContainer>
</template>

<script>
    import MainContainer from '@/layouts/MainContainer.vue';
    import BuildingsSelection from '@/layouts/BuildingsSelection.vue';
    import ContentLayout from '@/layouts/ContentLayout.vue';
    import ApartmentsList from '@/components/ApartmentsList.vue';
    import DirectionLine from '@/components/DirectionLine.vue';

    export default {
        name: "ApartmentsPage",
        components: {
          MainContainer,
          BuildingsSelection,
          ContentLayout,
          ApartmentsList,
          DirectionLine
        },
        props: {

        }
    }
</script>

<style lang="scss">
  .apartments-page__direction{
    @media screen and (max-width: 768px) {
        display: none;
    }
  }
</style>