<template>
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M13.596 9.61602L10.184 0.984009L7.3 6.02802L4.428 0.984009L1.004 9.61602H0V10.924H5.164V9.61602H4.392L5.14 7.46402L7.3 11.016L9.46 7.46402L10.208 9.61602H9.436V10.924H14.6V9.61602H13.596Z" 
            :fill='currentTheme'
        />
    </svg>
</template>
<script>

    const themes = {
        default: "#A1A2A3",
        yellow: "#FFAB2D",
        orange: "#EB5757",
        red: "#AB1B1D",
    }
    
    export default {
        name: "MetroSvg",
        props: {
            theme: {
                type: String,
                required: false,
                default: "default"
            }
        },
        data(){
            return {
                currentTheme: themes[this.theme]
            }
        },
    }
</script>
<style lang="">
    
</style>