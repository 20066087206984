<template>
    <div class="checkbox">
        <input 
            class="checkbox__input"
            :id="uuid" 
            type="checkbox"
        />
        <label 
            class="checkbox__label"
            :for="uuid" 
        >
            <slot/> 
        </label>
    </div>
</template>

<script>
    import { uuid } from 'vue-uuid' // and there is a useId in the react :c

    export default {
        name: "CheckboxCustom",
        props: {

        },
        data () {
            return {
                uuid: uuid.v1()
            }
        },
    }
</script>

<style lang="scss">
    .checkbox {
        &__input {
            display: none;
            &:checked ~ .checkbox__label {
                background: #009B47;
                color: #FFFFFF;
            }
        }

        &__label {
            cursor: pointer;
            user-select: none;
            display: block;
            color: #112132;
            background: rgba(55, 110, 164, 0.06);
            border-radius: 10px;
            padding: 15px 15px 11px 15px;
            height: 100%;
            text-align: center;
            font-size: 16px;
        }
    }
</style>