<template>
    <div class="switch">
        <div 
            class="switch__item" 
            v-for="(item,index) in data" 
            :class="{switch__item_selected: selected?.label === item?.label}"
            :key="index"
            @click="selected = item"
        >
            <p class="switch__label">{{ item.label }}</p>
        </div>
    </div>
</template>
<script>
    export default {
        name: "SwitchComponent", 
        props: {
            data: {
                type: Array
            }
        },
        data() {
            return {
                selected: null
            }
        }
    }
</script>
<style lang="scss">
    .switch{
        display: flex;
        gap: 10px;
        overflow-x: scroll;

        &::-webkit-scrollbar{
            display: none;
        }

        &__item{
            border-radius: 20px;
            background-color: transparent;
            cursor: pointer;
            user-select: none;

            &_selected{
                background: #1122330F;
            }
        }

        &__label{
            padding: 8px 30px;
            line-height: 30px;
            font-size: 16px;
            white-space: nowrap;
        }
    }
</style>

<!-- #1122330F -->