import { createRouter, createWebHashHistory } from 'vue-router'

import TheMainPage from './pages/MainPage.vue'
import TheApartmentsPage from './pages/ApartmentsPage.vue'
import TheAllApartmentsPage from './pages/AllApartmentsPage.vue'
import TheShowApartmentsPage from './pages/ShowApartmentsPage.vue'
import TheShowHousePage from './pages/ShowHousePage.vue'
import TheHouseMapPage from './pages/HouseMapPage.vue'

const routes = [
    { path: '/page1', component: TheMainPage, alias: '/' },
    { path: '/page2', component: TheHouseMapPage },
    { path: '/page3', component: TheShowHousePage },
    { path: '/page4', component: TheShowApartmentsPage },
    { path: '/page5', component: TheApartmentsPage },
    { path: '/page6', component: TheAllApartmentsPage },
]

export default createRouter ({
    history: createWebHashHistory(),
    routes,
})