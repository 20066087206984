<template>
    <SelectionLayout class="houses-table-selection">
        <FlexLayout 
            :gap="18.5" 
            flexDirection="column"
        >
            <Text>Квартиры</Text>
            <FlexLayout 
                :gap="12"
                :flexible="true"
            >
                <FieldHandler
                    label="Количество комнат"
                >
                    <GridLayout 
                        :gap="5"
                        gridTemplateColumns="max-content 1fr 1fr 1fr 1fr"
                    >
                        <Checkbox>
                            Студия
                        </Checkbox>
                        <Checkbox>
                            1
                        </Checkbox>
                        <Checkbox>
                            2
                        </Checkbox>
                        <Checkbox>
                            3
                        </Checkbox>
                        <Checkbox>
                            4
                        </Checkbox>
                    </GridLayout>
                </FieldHandler>
                <FieldHandler
                    label="Цена"
                >
                    <Select :options=[1,2,3,4]>
                        <CoinsSvg/>
                    </Select>
                </FieldHandler>
                <FieldHandler
                    label="Площадь"
                >
                    <Select :options=[1,2,3,4]>
                        <RulerSvg/>
                    </Select>
                </FieldHandler>
                <FieldHandler
                    label="Срок сдачи"
                >
                    <Select :options=[1,2,3,4]>
                        <CalendarSvg/>
                    </Select>
                </FieldHandler>
            </FlexLayout>
        </FlexLayout>
    </SelectionLayout>
</template>

<script>

    import Select from "@/components/Select.vue";
    import SelectionLayout from "@/layouts/SelectionLayout.vue"
    import FlexLayout from "@/layouts/FlexLayout.vue"
    import GridLayout from "@/layouts/GridLayout.vue";
    import Text from "@/components/Text.vue";
    import Checkbox from "@/components/Checkbox.vue";
    import FieldHandler from "@/components/FieldHandler.vue";

    import CoinsSvg from "@/components/svg/CoinsSvg.vue"
    import RulerSvg from "@/components/svg/RulerSvg.vue"
    import CalendarSvg from "@/components/svg/CalendarSvg.vue"

    export default {
        name: "HousesSelection",
        components: {
            SelectionLayout,
            FlexLayout,
            GridLayout,
            Text,
            FieldHandler,
            Select,
            CoinsSvg,
            RulerSvg,
            CalendarSvg,
            Checkbox
        }
    }
</script>

<style lang="scss">
    
</style>