<template>
    <div class="description">
        <p class="description__text">
            <slot />
            <span class="description__shadow" v-if="unwrapText"></span>
        </p>
        <p 
            class="description__unwrap-text"
            v-if="unwrapText"
        >
            Показать полностью
        </p>
    </div>
</template>

<script>

    export default {
        name: "DescriptionCustom",
        props: {
            unwrapText: Boolean
        }        
    }

</script>

<style lang="scss">
    .description {
        position: relative;
        
        &__text {
            font-size: 16px;
            line-height: 26px;
            color: #333333;
            max-height: 99px;
            overflow: hidden;
            
        }

        &__shadow{
            position: absolute;
                pointer-events: none;
                width: 100%;
                height: 60px;
                z-index: 99;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
                transform: translateY(-50%);
                bottom: 0;
                left: 0;
        }
        
        &__unwrap-text {
            color: #009B47;
            font-size: 16px;
            line-height: 35px;
            font-weight: 600;
            cursor: pointer;

        }

    }
</style>
