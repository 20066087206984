<template>
    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3395 2.71875C11.8796 1.03125 9.73275 0 7.5 0C5.26725 0 3.12037 0.9375 1.66049 2.71875C0.458241 4.21875 -0.142885 6.09375 0.0288651 7.96875C0.0288651 8.34375 0.11474 8.71875 0.200615 9C0.973491 12.9375 4.92375 16.4062 6.64125 17.7188C6.89887 17.9062 7.1565 18 7.5 18C7.8435 18 8.10113 17.9062 8.35875 17.7188C9.99038 16.4062 14.0265 12.9375 14.7994 9C14.8853 8.71875 14.8853 8.34375 14.9711 7.96875C15.1429 6.09375 14.5418 4.21875 13.3395 2.71875ZM7.5 10C6.21187 10 5.5 9.65625 5.5 8.15625C5.5 6.65625 6.126 5.53125 7.5 5.53125C8.874 5.53125 9.5 6.65625 9.5 8.15625C9.5 9.65625 8.78813 10 7.5 10Z" fill="#009B47"/>
    </svg>
</template>
<script>
    export default {
        
    }
</script>
