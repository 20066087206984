
<template>
    <div class="header">
        <div class="header__top">
            <Container class="header__top-container">
                <div class="header__left">
                    <a class="header__top-link">О компании</a>
                    <a class="header__top-link">Контакты</a>
                </div>
                <div class="header__right">
                    <a class="header__top-link">
                        <PointSvg/>
                        Москва и область
                    </a>
                </div>
            </Container>
        </div>
        <div class="header__bottom">
            <Container class="header__bottom-container">
                <div class="header__title">
                    <div class="header__burger" @click="burger_open = !burger_open" :class="{ header__burger_active: burger_open }">
                    </div>
                    <img class="header__logo" alt="Vue logo" src="../assets/logo.png">
                </div>
                <div class="header__nav">
                    <div class="header__nav-item">
                        <p class="header__nav-title">
                            Недвижимость
                        </p>
                        <ArrowDownSvg class="header__nav-arrow"/>
                        <div class="header__nav-popup">
                            <ul class="header__nav-popup-list">
                                <li class="header__nav-popup-item">Каталог новостроек</li>
                                <li class="header__nav-popup-item">Квартиры в Новостройках</li>
                                <li class="header__nav-popup-item">Вторичное жилье</li>
                                <li class="header__nav-popup-item">Застройщики</li>
                            </ul>
                        </div>
                    </div>
                    <div class="header__nav-item">
                        <p class="header__nav-title">
                            Ипотека
                        </p>
                        <ArrowDownSvg class="header__nav-arrow"/>
                        <div class="header__nav-popup">
                            <ul class="header__nav-popup-list">
                                <li class="header__nav-popup-item">Предложения банков</li>
                                <li class="header__nav-popup-item">Рефинансирование</li>
                            </ul>
                        </div>
                    </div>
                    <div class="header__nav-item">
                        <p class="header__nav-title">
                            Калькуляторы
                        </p>
                        <ArrowDownSvg class="header__nav-arrow"/>
                        <div class="header__nav-popup">
                            <ul class="header__nav-popup-list">
                                <li class="header__nav-popup-item">Сумма накоплений</li>
                                <li class="header__nav-popup-item">Выгода от рефинансирования</li>
                                <li class="header__nav-popup-item">Сумма военной ипотеки</li>
                            </ul>
                        </div>
                    </div>
                    <div class="header__nav-item">
                        <p class="header__nav-title">
                            Военнослужащим
                        </p>
                        <ArrowDownSvg class="header__nav-arrow"/>
                        <div class="header__nav-popup">
                            <ul class="header__nav-popup-list">
                                <li class="header__nav-popup-item">База знаний</li>
                                <li class="header__nav-popup-item">Вопросы и ответы</li>
                                <li class="header__nav-popup-item">Акции</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="header__aside" :class="{ header__aside_open: burger_open }">
                    <div class="header__aside-contacts">
                        <div>
                            <p class="header__contacts-phone">
                                +7 912 992 53 84
                            </p>
                            <p class="header__contacts-time">
                                Пн-Пт 09:00-18:00
                            </p>
                        </div>
                        <button class="header__contacts-button">
                            Позвонить
                        </button>
                    </div>
                    <div class="header__aside-item">
                        <p class="header__aside-title">Купить</p>
                        <ul class="header__aside-list">
                            <li class="header__aside-link">Каталог квартир</li>
                            <li class="header__aside-link">Каталог новостроек</li>
                            <li class="header__aside-link">Вторичное жильё</li>
                            <li class="header__aside-link">Застройщики</li>
                        </ul>
                    </div>
                    <div class="header__aside-item">
                        <p class="header__aside-title">Ипотека</p>
                        <ul class="header__aside-list">
                            <li class="header__aside-link">Список банков</li>
                            <li class="header__aside-link">Рефинансирование</li>
                        </ul>
                    </div>
                    <div class="header__aside-item">
                        <p class="header__aside-title">Военнослужащим</p>
                        <ul class="header__aside-list">
                            <li class="header__aside-link">База знаний</li>
                            <li class="header__aside-link">Вопросы и ответы</li>
                            <li class="header__aside-link">Акции</li>
                        </ul>
                    </div>
                    <div class="header__aside-item">
                        <p class="header__aside-title">Калькуляторы</p>
                        <ul class="header__aside-list">
                            <li class="header__aside-link">Сумма накоплений</li>
                            <li class="header__aside-link">Сумма ипотеки</li>
                            <li class="header__aside-link">Выгода от рефинансирования</li>
                        </ul>
                    </div>
                    <div class="header__aside-item">
                        <p class="header__aside-title">О компании</p>
                        <ul class="header__aside-list">
                            <li class="header__aside-link">Контакты</li>
                            <li class="header__aside-link">Повоенке</li>
                            <li class="header__aside-link">Новости</li>
                            <li class="header__aside-link">Наша команда</li>
                            <li class="header__aside-link">Отзывы</li>
                            <li class="header__aside-link">Гарантии</li>
                        </ul>
                    </div>
                </div>
                <div class="header__actions">
                    <HeartSvg/>
                    <SearchSvg/>
                </div>
                <div class="header__contacts">
                    <div>
                        <p class="header__contacts-phone">
                            +7 912 992 53 84
                        </p>
                        <p class="header__contacts-time">
                            Пн-Пт 09:00-18:00
                        </p>
                    </div>
                    <p 
                        class="header__contacts-call"
                        @click="contact_open=!contact_open"
                    >
                        Заказать звонок
                    </p>
                    <button class="header__contacts-button">
                        Позвонить
                    </button>
                    <div class="header__contacts-form" v-if="contact_open">
                        <div class="header__contacts-form-title">
                            Заказать звонок 
                            <CloseSvg
                                class="header__close-button"
                                @click="contact_open=!contact_open"
                            />
                        </div>
                        <FieldHandler label="Номер телефона">
                            <Input placeholder="+7 000 000 00 00"/>
                        </FieldHandler>
                        <Button>
                            Заказать звонок
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    </div>
</template>

<script>
    import HeartSvg from "./svg/HeartSvg.vue"
    import SearchSvg from "./svg/SearchSvg.vue"
    import ArrowDownSvg from "./svg/ArrowDownSvg.vue"
    import CloseSvg from "./svg/CloseSvg.vue"
    import PointSvg from "./svg/PointSvg.vue"
    import Container from "@/layouts/Container.vue"
    import Input from "@/components/Input.vue"
    import Button from "@/components/Button.vue"
    import FieldHandler from "@/components/FieldHandler.vue"

    export default {
        name:  "HeaderComponent",
        components: {
            HeartSvg,
            SearchSvg,
            ArrowDownSvg,
            PointSvg,
            Container,
            Input,
            Button,
            FieldHandler,
            CloseSvg
        },
        data(){
            return {
                burger_open: false,
                contact_open: false,
            }
        },
        watch: {
            burger_open(val){
                document.body.classList.toggle("overflowed", val)
            }
        }

        
    }
</script>

<style lang="scss">
    @import "../style/_variables.scss";

    .header{
        background-color: white;
        position: relative;
        
        &__container{
            margin: 0 auto;
            max-width: 1200px;
        }

        // Top

        &__top{
            background-color: $background-primary;

            @media all and (max-width: 768px) {
                display: none;
            }
        }

        &__top-container{
            display: flex;
            justify-content: space-between;
        }

        &__left{
            display: flex;
            gap: 33px;
        }

        &__right{
            display: flex;
            gap: 40px;
        }

        &__top-link{
            color: $text-secondary;
            display: flex;
            align-items: center;
            gap: 6px;
            cursor: pointer;
            letter-spacing: 0.01em;
        }

        // Bottom

        &__bottom-container{
            display: grid;
            grid-template-columns: max-content max-content 1fr max-content;

            @media all and (max-width: 1024px) {
                grid-template-columns: 1fr max-content;
            }

            @media all and (max-width: 768px) {
                grid-template-columns: 1fr;
                gap: 24px;
            }
        }

        &__title{
            margin-right: 41px;
            display: flex;
            align-items: center;
        }

        &__logo{
            width: 176px;

            @media all and (max-width: 1024px) {
                margin-left: 15px;
            }
        }

        &__burger{
            width: 20px;
            cursor: pointer;
            display: none;

            &:before,
            &:after,
            & div {
                background: black;
                content: "";
                display: block;
                height: 3px;
                border-radius: 3px;
                margin: 3px 0;
                transition: 0.5s;
            }

            &_active{
                &:before {
                    transform: translateY(6px) rotate(135deg);
                }
                &:after {
                    transform: translateY(-6px) rotate(-135deg);
                }
                & div {
                    transform: scale(0);
                }
            }
            

            @media all and (max-width: 1024px) {
                display: block
            }
        }

        // Nav
        &__nav{
            display: flex;
            gap: 32px;
            align-items: center;

            @media all and (max-width: 1024px) {
                display: none;
            }
        }

        &__nav-item{
            position: relative;
            display: flex;
            align-items: center;
            gap: 9px;
            font-size: 14px;
            cursor: pointer;
            height: 100%;

            &:hover{

                .header__nav-popup{
                    visibility: visible;
                    opacity: 1;
                }

                .header__nav-arrow{
                    transform: rotate(-180deg);
                }
            }
        }

        &__nav-title{
            color: $text-primary;
            font-size: 16px;
        }

        &__nav-arrow{
            transition: .2s linear;

            @media all and (max-width: 1024px) {
                display: none;
            }
        }

        &__nav-popup{
            position: absolute;
            left: 0;
            top: 100%;
            visibility: hidden;
            opacity: 0;
            z-index: 100;

            @media all and (max-width: 1024px) {
                opacity: 1;
                visibility: visible ;
                position: inherit;
                top: unset;
                left: unset;
                position: unset;
            }
        }

        &__nav-popup-list{
            margin-top: 20px;
            width: max-content;
            padding: 20px 25px 20px 25px;
            border-radius: 10px;
            background-color: white;

            @media all and (max-width: 1024px) {
                padding: 0;
                margin-top: 15px;
                
            }
        }

        &__nav-popup-item{
            font-size: 16px;
        }

        &__nav-popup-item:not(:last-child){
            margin-bottom: 12px;
        }

        // Aside
        &__aside{
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: white;
            padding-top: 20px;
            padding: 13px 14px 0;
            z-index: 1000;
            transform: translateX(-100%);
            transition: .2s linear;

            &_open{
            transform: translateX(0);
                height: 92vh;
                overflow-y: scroll;
                &::-webkit-scrollbar{
                    display: none;
                }
            }

            @media all and (min-width: 1024px) {
                display: none;
               }
        }

        &__aside-item{
            margin-bottom: 30px;

            &:last-child{                                                           
                margin-bottom: 82px;
            }

        }

        &__aside-title{
            margin-bottom: 15px;
            font-size: 20px;
        }

        &__aside-list{}

        &__aside-link{
            font-size: 20px;
            color: $text-secondary;
            cursor: pointer;

            &:not(:last-child){
                margin-bottom: 15px;
            }
        }

        &__aside-contacts{
            display: flex;
            justify-content: space-between;
            margin-bottom: 21px;

            @media screen and (min-width: 768px){
                display: none;
            }
        }
       
       // Actions
        &__actions{
            align-self: center;
            justify-self: center;
            display: flex;
            gap: 15px;

            & > * {
                cursor: pointer;
            }

            @media all and (max-width: 1024px) {
                display: none;
            }
        }

        // Contacts
        &__contacts{
            position: relative;
            @media all and (max-width: 1024px) {
                display: flex;
                justify-content: space-between;
                gap: 16px;

                &-phone{
                    color: $text-positive;
                    margin-bottom: 8px;
                }

                &-time{
                    display: block !important;
                }

                &-call{
                    display: none;
                }

                &-button{
                    display: block !important;
                }
            }

            @media all and (max-width: 768px){
                display: none;
            }
        }

        &__contacts-phone{
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 4px;
        }

        &__contacts-time{
            display: none;
            color: $text-secondary;
            font-size: 14px;
        }

        &__contacts-button{
            display: none;
            color: white;
            font-size: 16px;
            padding: 7px 44px;
            background-color: $text-positive;
            border-radius: 11px;
        }
        
        &__contacts-call{
            text-align: right;
            color: $text-positive;
            font-size: 14px;
            letter-spacing: 0.01em;
            font-weight: 500;
            cursor: pointer;
            user-select: none;
        }

        &__contacts-form{
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 22px 27px 32px 15px;
            background-color: #fff;
            top: calc(100% + 20px);
            right: 0;
            border-radius: 14px;
            z-index: 1;
        }

        &__contacts-form-title{
            font-size: 20px;
            font-weight: 500;
            margin-left: 3px;
            display: flex;
            justify-content: space-between;
            width: 250px;
        }

        &__close-button{
            cursor: pointer;
        }
    }
</style>