<template lang="">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.1052 15.076L13.86 11.8309C13.7136 11.6844 13.515 11.6031 13.3067 11.6031H12.7762C13.6745 10.4541 14.2083 9.00891 14.2083 7.4368C14.2083 3.69693 11.178 0.666626 7.43814 0.666626C3.69827 0.666626 0.667969 3.69693 0.667969 7.4368C0.667969 11.1767 3.69827 14.207 7.43814 14.207C9.01025 14.207 10.4554 13.6732 11.6044 12.7748V13.3054C11.6044 13.5137 11.6858 13.7122 11.8322 13.8587L15.0774 17.1038C15.3833 17.4098 15.8781 17.4098 16.1808 17.1038L17.1019 16.1827C17.4079 15.8767 17.4079 15.382 17.1052 15.076ZM7.43814 11.6031C5.13693 11.6031 3.27188 9.74126 3.27188 7.4368C3.27188 5.13559 5.13368 3.27054 7.43814 3.27054C9.73935 3.27054 11.6044 5.13234 11.6044 7.4368C11.6044 9.73801 9.7426 11.6031 7.43814 11.6031Z" fill="#143352" fill-opacity="0.65"/>
    </svg>
</template>

<script>
export default {
    
}
</script>

<style lang="">
    
</style>