<template>
    <MainContainer>
        <ContentLayout white noPadding>
            <div class="container">
                <UnderHeaderBlock class="under-header-top"/>
                <DirectionLine class="under-header-bottom" :steps="['Недвижимость', 'Ипотека', 'Рефинансирование']"/>
            </div>
            <GridLayout class="grid-container">
                <div>
                    <Album class="album-block"/>
                    <RoomCharacteristics
                        title="ЖК Новое Внуково"
                        class="room-characteristics_main"
                        :data="[
                            {
                                label: 'Срок сдачи',
                                data: 'I кв. 22 - II кв. 23',
                                dashed: true
                            },
                            {
                                label: 'Класс',
                                data: 'Бизнес'
                            },
                            {
                                label: 'Этажность',
                                data: 'От 5 до 10',
                                dashed: true
                            },
                            {
                                label: 'Корпусов',
                                data: '6 корпусов',
                                dashed: true
                            },
                            {
                                label: 'Военная ипотека',
                                data: 'Возможна',
                            },
                            {
                                label: 'Тип дома',
                                data: 'Многоквартирный'
                            },
                            {
                                label: 'Отделка',
                                data: '3 вида',
                                dashed: true
                            },
                            {
                                label: 'Парковка',
                                data: '3 вида',
                                dashed: true
                            }
                        ]"
                    />
                    <RoomCharacteristics
                        title="ЖК Новое Внуково"
                        class="room-characteristics_phone"
                        columns="1fr 1fr"
                        :data="[
                            {
                                label: 'Срок сдачи',
                                data: 'I кв. 22',
                                dashed: true
                            },
                            {
                                label: 'Этаж',
                                data: '5 из 10',
                                dashed: true
                            },
                            {
                                label: 'Площадь',
                                data: '34м (Кухня 14м)',
                                dashed: true
                            },
                            {
                                label: 'Отделка',
                                data: 'Черновая',
                                dashed: true
                            },
                        ]"
                    />
                </div>
                <div class="mini-map-container">
                    <div>
                        <img 
                            class="mini-map"
                            src="@/assets/img/mini-map.png" 
                            alt="mini-map"
                        >
                    </div>
                    <CallOrdering />
                </div>
            </GridLayout>
            <Switch 
                class="switch-block"
                :data="[
                    {
                        label: 'Квартиры',
                    },
                    {
                        label: 'Ипотека',
                    },
                    {
                        label: 'О комплексе',
                    },
                    {
                        label: 'Инфраструктура',
                    },
                    {
                        label: 'Похожие комплексы',
                    }
                ]"
            />
            <ApartmentsList complex="ЖК Новая Рига"/>
            <div class="bank-list-container">
                <Heading 
                    title="Военная ипотека" 
                    :toggleList="true"
                    :options="[
                        {
                            text: 'Предложения банков',
                            selected: true
                        },
                        {
                            text: 'Аккредитованные банки',
                            selected: false
                        }
                    ]"
                >
                    <BankList /> 
                    <template #open-button>
                        <Button themes="dark small">Показать целиком</Button>
                    </template>
                </Heading>
            </div>
            <AboutHouse/>
            <div class="map-container">
                <Map title="Инфраструктура"/>
            </div>
            <div class="location-container">
                <Location/>
            </div>
        </ContentLayout>
        <NearestResComplexList/>
    </MainContainer>
</template>
<script>
    import MainContainer from '@/layouts/MainContainer.vue';
    import ContentLayout from '@/layouts/ContentLayout.vue';
    import ApartmentsList from '@/components/ApartmentsTable.vue';
    import DirectionLine from '@/components/DirectionLine.vue';
    import Heading from '@/components/Heading.vue';
    import Button from '@/components/Button.vue';
    import BankList from '@/components/BankList.vue';
    import Album from '@/components/Album.vue';
    import GridLayout from '@/layouts/GridLayout.vue';
    import RoomCharacteristics from '@/components/RoomCharacteristics.vue';
    import CallOrdering from '@/components/CallOrdering.vue';
    import Switch from '@/components/Switch.vue';
    import AboutHouse from '@/components/AboutHouse.vue';
    import Map from '@/components/Map.vue';
    import UnderHeaderBlock from '@/components/UnderHeaderBlock.vue';
    import Location from '@/components/Location.vue';
    import NearestResComplexList from '@/dataComponents/NearestResComplexList.vue';
    
    export default {
        components: {
            MainContainer,
            NearestResComplexList,
            ContentLayout,
            ApartmentsList,
            Heading,
            BankList,
            Album,
            GridLayout,
            RoomCharacteristics,
            CallOrdering,
            Switch,
            AboutHouse,
            Map,
            Location,
            Button,
            DirectionLine,
            UnderHeaderBlock
        }
    }
</script>

<style lang="scss" scoped>
    .under-header-top{
        padding-bottom: 9px;

        @media all and (min-width: 768px) {
            display: none;
        }
    }
    .under-header-bottom{
        @media all and (max-width: 768px) {
            display: none;
        }
    }

    .room-characteristics{

        &_main{
            @media all and (min-width: 1024px) {
                margin-bottom: 51px;
            }

            @media all and (max-width: 1024px) {
                display: none;
            }
        }

        &_phone{
            @media all and (min-width: 1024px) {
                display: none;
            }

            @media all and (min-width: 768px) {
                margin-bottom: 59px;
            }

            @media all and (max-width: 768px) {
                max-width: 90%;
            }

            @media all and (max-width: 425px) {
                max-width: 400px;
            }
        }
    }
    
    .album-block{

        padding: 0 0 0 20px;

        @media all and (max-width: 1024px) {
            padding: 0 20px;
        }

        @media all and (max-width: 768px) {
            padding: 0;
        }
    }
    .mini-map{
        width: 345px;
        height: 355px;
        margin-bottom: 17px;
        border-radius: 20px;
    }

    .switch-block{
        margin-left: 20px;
    }
    .grid-container{

        @media all and (min-width: 1024px) {
            grid-template-columns: 1fr max-content;
            gap: 24px;
        }
    }
    .mini-map-container{
        padding-right: 22px;
        @media all and (max-width: 1024px) {
            display: none;
        }
    }

    .container{
        padding: 0 20px;
    }

    .map-container{
        @media all and (max-width: 768px) {
            display: none;
        }
    }

    .location-container{
        padding: 0 20px;
        display: none;
        margin-bottom: 78px;

        @media all and (max-width: 768px) {
            display: block;
        }
    }

    .bank-list-container{
        margin-bottom: 90px;
        padding: 0 0 0 20px;

        @media all and (min-width: 1024px) {
            max-width: 826px;
        }

        @media all and (max-width: 1024px) {
            margin-bottom: 38px;
        }
    }
</style>