<template>
    <div class="call-ordering">
        <p class="call-ordering__title">Выгода с повоенке</p>
        
        <div class="call-ordering__tag-list">
            <Tag>Скидка 10%</Tag>
            <Tag>Бесплатное оформление</Tag>
        </div>

        <div class="call-ordering__manager-info">
            
            <div class="call-ordering__company-logo">
                <img
                    class="call-ordering__logo"
                    :src='require("../assets/logo_mini.png")'
                    alt="company logo"
                />
            </div>

            <div class="call-ordering__manager-data">
                <p class="call-ordering__role">Менеджер</p>
                <p class="call-ordering__phone">+7 912 992 53 84</p>
            </div>
        </div>

        
        <div class="call-ordering__customer-data">
            <Input placeholder="Введите номер"/>
            <Button>Заказать звонок</Button>
        </div>
    </div>
</template>

<script >
    import Tag from './Tag.vue';
    import Input from './Input.vue';
    import Button from "./Button.vue"

    export default {
        name: "CallOrdering",
        components: {
            Tag,
            Input,
            Button
        }
    }
</script>

<style lang="scss">
    .call-ordering {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
        background: #FFFFFF;
        border-radius: 14px;
        padding: 38px 26px 31px;
        box-shadow: 0px 14px 14px 0px #0000000D;
        height: max-content;


        @media all and (max-width: 1024px){
            display: none;
        }
        
        &__title {
            color: #112132;
            font-size: 22px;
            letter-spacing: 0.01em;
        }

        &__tag-list {
            display: flex;
            flex-wrap: wrap;
            gap: 6.57px;
        }

        &__manager-info {
            display: flex;
            gap: 13px;
        }

        &__company-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            background: rgba(55, 110, 164, 0.06);
            border-radius: 15.0857px;
        }

        &__logo {
            width: 33px;
            height: 30px;
            object-fit: contain;
        }

        &__manager-data {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        &__role {
            font-size: 14px;
            color: rgba(17, 34, 51, 0.65);
            letter-spacing: 0.01em;
        }

        &__phone {
            color: #112132;
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0.01em;
        }

        &__customer-data {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }
    }
</style>