<template>
    <p class="text">
        <slot />
    </p>
</template>

<script>
    export default {
        name: "TextComponent",
        props: {

        }
    }
</script>

<style lang="scss">
    .text {
        font-size: 37px;
        font-weight: 600;
    }
</style>