<template>
    <div class="field-handler">
        <p class="field-handler__label">{{label}}</p>
        <slot />
    </div>
</template>

<script>
    export default {
        name: "FieldHandler",
        props: {
            label: String
        }
    }
</script>

<style lang="scss">
    
    .field-handler {
        display: grid;
        grid-template-rows: min-content 1fr;

        &__label {
            color: #143352A6;
            font-size: 14px;
            margin-bottom: 6px;
            margin-left: 4px;
        }
    }

</style>