<template lang="">
    <MainContainer>
        <Banner class="house-map__banner">
            <DirectionLine :steps="['Недвижимость', 'Ипотека', 'Рефинансирование']"/>
        </Banner>
        <BuildingsSelection class="house-map__selection" title="72 квартиры в ЖК Новая Рига" truncated/>
        <div class="house-map__wrapper">
            <img class="house-map__img" src="@/assets/img/map_big.png" alt="map">
            <Button class="house-map__button house-map__button-list" themes="white svg">
                <template #svg>
                    <ListSvg/>
                </template>
                Показать списком
            </Button>
            <Button class="house-map__button house-map__button-options" themes="green svg">
                <template #svg>
                    <OptionsSvg/>
                </template>
            </Button>
        </div>
    </MainContainer>  
</template>
<script>
    import MainContainer from '@/layouts/MainContainer.vue';
    import BuildingsSelection from '@/layouts/BuildingsSelection.vue';
    import Banner from '@/layouts/Banner.vue';
    import Button from "@/components/Button.vue";
    import DirectionLine from "@/components/DirectionLine.vue";
    import ListSvg from "@/components/svg/ListSvg.vue";
    import OptionsSvg from "@/components/svg/OptionsSvg.vue";

    export default {
        components: {
            MainContainer,
            BuildingsSelection,
            Banner,
            Button,
            ListSvg,
            OptionsSvg,
            DirectionLine
        },
        data(){
            return {
                width: 0,
            }
        },
        mounted(){
            window.addEventListener('resize', this.handleResize)
            this.handleResize()
        },
        unmounted(){
            window.removeEventListener('resize', this.handleResize)
            document.body.classList.toggle("overflowed", false)

        },
        methods: {
            handleResize() {
                this.width = window.innerWidth <= 768 
            }
        },
        watch: {
            width(val){
                document.body.classList.toggle("overflowed", val)
            }
        }
        
    }
</script>
<style lang="scss">
    
    .house-map{
        

        &__img{
            width: 100%;
            height: 650px;

            @media screen and (max-width: 768px) {
                height: 100%;
            }
        }
        

        &__wrapper{
            position: relative;
            @media screen and (max-width: 768px) {
                position: fixed;
                left: 0;
                z-index: 999;
                height: 90%;
                object-fit: cover;
            }
        }

        &__selection{
            margin-bottom: 56px;
            margin-top: 49px;
            padding: 0 20px;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        &__banner{
            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        &__button{
            position: absolute;
        }

        &__button-list{
            top: 37px;
            left: 21px;

            @media screen and (max-width: 768px) {
                top: 15px;
                left: 12px;
            }
        }

        &__button-options{
            display: none;

            @media screen and (max-width: 768px) {
                width: 48px;
                height: 48px;
                display: block;
                bottom: 64px;
                right: 31px;
            }
        }
    }
</style>