<template lang="">
    <svg width="25" height="13" viewBox="0 0 25 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2318 12.9455H13.7261C13.7261 12.9455 14.1773 12.9001 14.4081 12.6739C14.6201 12.4659 14.6133 12.0756 14.6133 12.0756C14.6133 12.0756 14.5841 10.248 15.5145 9.97888C16.4321 9.7136 17.6101 11.7452 18.8586 12.5264C19.8027 13.1174 20.5202 12.988 20.5202 12.988L23.8589 12.9455C23.8589 12.9455 25.6053 12.8473 24.7771 11.5957C24.7094 11.4934 24.2947 10.6698 22.2947 8.97771C20.2011 7.20662 20.4817 7.49316 23.0034 4.42963C24.5392 2.56394 25.153 1.42499 24.9612 0.937206C24.7785 0.472448 23.6488 0.595218 23.6488 0.595218L19.8898 0.616401C19.8898 0.616401 19.6109 0.581818 19.4044 0.694477C19.2023 0.804648 19.0727 1.06206 19.0727 1.06206C19.0727 1.06206 18.4775 2.50568 17.6843 3.73362C16.0104 6.32429 15.341 6.46142 15.0674 6.3003C14.4309 5.92533 14.5899 4.79425 14.5899 3.99047C14.5899 1.47972 15.0077 0.432889 13.7763 0.161915C13.3678 0.0720443 13.0668 0.0125853 12.0217 0.0028761C10.6804 -0.00956132 9.54536 0.00664781 8.90254 0.293671C8.47488 0.484565 8.14493 0.909844 8.346 0.934317C8.59451 0.964488 9.15705 1.07273 9.4553 1.44265C9.84062 1.92048 9.82716 2.99315 9.82716 2.99315C9.82716 2.99315 10.0486 5.94868 9.31022 6.3157C8.80359 6.5675 8.10848 6.05347 6.61614 3.70304C5.85166 2.4991 5.27425 1.16814 5.27425 1.16814C5.27425 1.16814 5.16307 0.919473 4.96446 0.786353C4.72361 0.625067 4.38706 0.573954 4.38706 0.573954L0.814843 0.595218C0.814843 0.595218 0.27872 0.608859 0.0817015 0.821418C-0.0935726 1.01063 0.0677053 1.40148 0.0677053 1.40148C0.0677053 1.40148 2.86417 7.36518 6.0309 10.3705C8.93485 13.1263 12.2318 12.9455 12.2318 12.9455Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>

<script>
    export default {
        name: "VkSvg"
    }
</script>
