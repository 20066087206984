<template lang="">
    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.48615 13.3977C0.702344 7.91231 0 7.34934 0 5.33337C0 2.57194 2.23857 0.333374 5 0.333374C7.76143 0.333374 10 2.57194 10 5.33337C10 7.34934 9.29766 7.91231 5.51385 13.3977C5.26555 13.7564 4.73443 13.7564 4.48615 13.3977ZM5 7.41671C6.1506 7.41671 7.08333 6.48397 7.08333 5.33337C7.08333 4.18277 6.1506 3.25004 5 3.25004C3.8494 3.25004 2.91667 4.18277 2.91667 5.33337C2.91667 6.48397 3.8494 7.41671 5 7.41671Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>
<script>
    export default {
        name: "PointSvg"
    }
</script>
