<template>
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.25 18.625C0.25 19.6602 1.08984 20.5 2.125 20.5H15.875C16.9102 20.5 17.75 19.6602 17.75 18.625V8H0.25V18.625ZM2.75 11.125C2.75 10.7812 3.03125 10.5 3.375 10.5H7.125C7.46875 10.5 7.75 10.7812 7.75 11.125V14.875C7.75 15.2188 7.46875 15.5 7.125 15.5H3.375C3.03125 15.5 2.75 15.2188 2.75 14.875V11.125ZM15.875 3H14V1.125C14 0.78125 13.7188 0.5 13.375 0.5H12.125C11.7812 0.5 11.5 0.78125 11.5 1.125V3H6.5V1.125C6.5 0.78125 6.21875 0.5 5.875 0.5H4.625C4.28125 0.5 4 0.78125 4 1.125V3H2.125C1.08984 3 0.25 3.83984 0.25 4.875V6.75H17.75V4.875C17.75 3.83984 16.9102 3 15.875 3Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>
<script>
    export default {
        name: "CalendarSvg"
    }
</script>
