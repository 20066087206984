<template>
    <p class="tag">
        <slot></slot>
    </p>
</template>

<script>
    export default {
        name: "TagComponent",
        props: {
            
        }
    }
</script>

<style lang="scss">
    .tag {
        width: max-content;
        border-radius: 5.8px;
        padding: 3.5px 10px;
        font-weight: 400;
        letter-spacing: 0.01em;
        font-size: 14px;
        color: #000000;
        background: rgba(55, 110, 164, 0.06);
    }
</style>

