<template>
    <div class="direction-line">
        <p
            class="direction-line__step"
            v-for="(step, i) of steps"
            :key="i"
        >
        {{ step }}
        </p>
    </div>
</template>
<script>
    export default {
        props: {
            steps: Array
        }
    }
</script>
<style lang="scss">
    .direction-line{
        display: flex;
        gap: 36px;
        margin: 0 auto;
        max-width: 1200px;
        padding: 19px 0;

        &__step{
            color: #1122338C;
            position: relative;

            &:not(:last-child)::after{
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                top: 25%;
                right: -9px;
                border-right: 1px solid #1122338C;
                border-bottom: 1px solid #1122338C;
                transform: rotate(-45deg);
                
            }
        }
    }
</style>