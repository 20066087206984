<template>
    <div 
        class="slider-switch"
        :class="{'slider-switch_gray': gray}"
    >
        <div class="slider-switch__item slider-switch__item_selected"/>
        <div class="slider-switch__item"/>
        <div class="slider-switch__item"/>
        <div class="slider-switch__item"/>
    </div>
</template>
<script>
export default {
    props: {
        length: Number,
        gray: Boolean
    } 
}
</script>
<style lang="scss">

    @import "@/style/variables.scss";

    .slider-switch{
        position: absolute;
        bottom: 10px;
        width: 100%;
        display: flex;
        gap: 6px;
        justify-content: center;

        &__item{
            background-color: #FFFFFF;
            opacity: 0.2;
            width: 39px;
            height: 3px;
            border-radius: 12px;

            &_selected{
                background-color: #FFFFFF;
                opacity: 0.5;
            }
        }

        &_gray{
            .slider-switch__item{
                background-color: $background-tetriary;
                border-radius: 6px;

                &_selected{
                    background-color: $text-tetriary;
                }
            }
        }
    }
</style>