<template>
    <div 
        class="container"
        :style="styleObject"
    >
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "ContainerComponent",
        props: {
            padding: {
                required: false,
                default: "13px 20px"
            }
        },
        data(){
            return {
                styleObject: {
                    padding: this.padding
                } 
            }
        }
    }
</script>

<style>
    .container{
        margin: 0 auto;
        max-width: 1200px;
    }
</style>