<template lang="">
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.3009 6.46938C6.40013 4.35919 9.79476 2.95703 11.4994 2.27678C16.3509 0.34707 17.3707 0.0138828 18.0264 0C18.1721 0 18.4926 0.0277658 18.7111 0.194359C18.8859 0.333187 18.9297 0.513664 18.9588 0.652491C18.9879 0.791319 19.0171 1.08286 18.9879 1.30498C18.7257 3.94271 17.5893 10.3427 17.0065 13.2858C16.7588 14.5353 16.2781 14.9518 15.8118 14.9934C14.792 15.0767 14.0198 14.3548 13.0437 13.744C11.4994 12.786 10.6398 12.1891 9.13914 11.2451C7.40541 10.1622 8.52723 9.56524 9.51794 8.59344C9.78019 8.34355 14.2529 4.45637 14.3404 4.1093C14.3549 4.06766 14.3549 3.90106 14.2529 3.81776C14.151 3.73447 14.0053 3.76223 13.8887 3.79C13.7284 3.81777 11.2808 5.37264 6.51668 8.44073C5.81736 8.89886 5.19088 9.12099 4.62269 9.10711C3.99621 9.09322 2.80153 8.77392 1.89824 8.49626C0.805552 8.16308 -0.068601 7.9826 0.00424505 7.39952C0.0479527 7.0941 0.485029 6.78868 1.3009 6.46938Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>

<script>
    export default {
        name: "TelegramSvg"
    }
</script>
