<template>
    <div class="nearest-residential-complex">
        <div class="nearest-residential-complex__image-block">
            <img
                class="nearest-residential-complex__avatar"
                src="https://th.bing.com/th/id/OIP.pcawuo6A9sjxnDsVxnX8YgHaFj?pid=ImgDet&rs=1"
                alt="avatar"
            />
            <SliderSwitch/>
        </div>
        <div class="nearest-residential-complex__content">
            <div class="nearest-residential-complex__content-header">
                <p class="nearest-residential-complex__title">ЖК Новая Рига</p>
                <HeartSvg/>
            </div>
            <div class="nearest-residential-complex__underground">
                <p>Рассказовка</p>
                <div class="nearest-residential-complex__time"><ManSvg/> <p>10 мин</p></div>
            </div>
            <div class="nearest-residential-complex__tag-list">
                <Tag>Скидка 10%</Tag>
                <Tag>0Р оформление</Tag>
                <Tag>С отделкой</Tag>
            </div>
        </div>
    </div>
</template>

<script>
    import HeartSvg from './svg/HeartSvg.vue';
    import ManSvg from '@/components/svg/ManSvg.vue'
    import Tag from './Tag.vue';
    import SliderSwitch from "@/components/SliderSwitch.vue"

    export default {
        name: "NearestResComplexCard",
        components: {
            Tag,
            HeartSvg,
            ManSvg,
            SliderSwitch
        }
    }
</script>

<style lang="scss">
    .nearest-residential-complex {
        border-radius: 11.7px 11.7029px 11.7029px 11.7029px;
        overflow: hidden;
        background: #FFFFFF;
        min-width: 360px;

        @media all and (max-width: 768px) {
            min-width: 335px;
        }

        &__image-block{
            position: relative;
        }

        &__avatar {
            max-width: 100%;
            border-radius: 11.7px 11.7029px 11.7029px 11.7029px;
        }
        &__content {
            padding: 19px 22px;
        }
        &__content-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__title {
            color: #112132;
            font-weight: 500;
            font-size: 21.0652px;
            line-height: 140%;
            letter-spacing: 0.01em;
        }
        &__underground {
            display: flex;
            gap: 10.83px;
            color: rgba(20, 51, 82, 0.65);
            font-weight: 400;
            font-size: 16px;
            // line-height: 140%;
            letter-spacing: 0.01em;
            margin-bottom: 8px;

        }

        &__time{
            display: flex;
            line-height: 17px;
        }

        &__tag-list {
            display: flex;
            flex-wrap: wrap;
            gap: 7px;
        }
    }
</style>