<template lang="">
    <svg width="14" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.24847 2.83295C6.93848 2.83295 7.49829 2.27313 7.49829 1.58313C7.49829 0.893127 6.93848 0.333313 6.24847 0.333313C5.55847 0.333313 4.99866 0.893127 4.99866 1.58313C4.99866 2.27313 5.55847 2.83295 6.24847 2.83295ZM8.70905 6.71519L8.10237 6.40794L7.8498 5.64243C7.46705 4.48114 6.39949 3.66876 5.18873 3.66616C4.25137 3.66355 3.73322 3.92914 2.7594 4.32231C2.19698 4.54884 1.73611 4.97847 1.46532 5.52526L1.29087 5.87938C1.08777 6.29077 1.25181 6.7907 1.6606 6.9964C2.0668 7.2021 2.56151 7.03546 2.76721 6.62406L2.94167 6.26994C3.0328 6.08768 3.18382 5.94447 3.37129 5.86896L4.06911 5.58775L3.67333 7.16825C3.53793 7.70984 3.68375 8.28527 4.06129 8.69928L5.62096 10.4022C5.80843 10.6079 5.94123 10.8552 6.00893 11.1234L6.48542 13.032C6.59738 13.4772 7.05044 13.7506 7.49569 13.6387C7.94093 13.5267 8.21433 13.0736 8.10237 12.6284L7.52433 10.311C7.45663 10.0428 7.32384 9.79286 7.13636 9.58977L5.95164 8.29569L6.39949 6.50689L6.5427 6.93651C6.6807 7.35572 6.97753 7.70203 7.3681 7.89991L7.97478 8.20716C8.38097 8.41286 8.87569 8.24622 9.08139 7.83482C9.28188 7.42602 9.11784 6.92089 8.70905 6.71519ZM2.74899 10.3787C2.66567 10.5896 2.54068 10.7797 2.37925 10.9385L1.07736 12.243C0.751884 12.5685 0.751884 13.0971 1.07736 13.4225C1.40283 13.748 1.92879 13.748 2.25427 13.4225L3.80092 11.8759C3.95975 11.7171 4.08473 11.527 4.17065 11.3161L4.52216 10.436C3.08227 8.86592 3.5145 9.34762 3.28797 9.03777L2.74899 10.3787Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>
<script>
    export default {
        
    }
</script>
 