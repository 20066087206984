<template>
    <div 
        class="content"
        :class="{content_white: white}"
        :style="containerStyle"
    >
        <div 
            class="content__container"
            :class="{content_noPadding: noPadding}"
        >
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContentLayout",
        props: {
            white: Boolean,
            noPadding: Boolean,
            containerStyle: Object
        }
    }
</script>

<style lang="scss">
    .content {
        
        background-color: #F3F6FA;

        &__container{
            margin: 0 auto;
            max-width: 1200px;
            padding: 0 7px;
        }

        &_white {
            background-color: white;
        }

        &_noPadding {
            padding: unset;
        }
    }
</style>