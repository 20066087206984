<template>
    <MainContainer>
        <Banner />
        <div class="main__selection">
          <HousesSelection title="72 новостройки"/>
        </div>
        <ContentLayout class="main__container">
          <div class="main__sort">
            <Button noPadding themes="white svg">
              <template v-slot:svg>
                <MapSvg/>
              </template>
              Показать на карте
            </Button>
            <SortButton>
              По цене
            </SortButton>
          </div>
          <GridLayout 
            gridTemplateColumns="repeat(auto-fit, minmax(292px,1fr))"
            :gap="24"
          >
            <CardHouse />
            <CardHouse />
            <CardHouse />
            <CardHouse />
            <CardHouse />
            <CardHouse />
            <CardHouse />
            <CardHouse />
            <CardHouse />
          </GridLayout>
        </ContentLayout>
      </MainContainer>
</template>

<script>
    import CardHouse from "@/components/CardHouse.vue";
    import SortButton from "@/components/SortButton.vue";
    import MapSvg from "@/components/svg/MapSvg.vue";
    import Button from "@/components/Button.vue";
    import GridLayout from "@/layouts/GridLayout.vue";
    import Banner from "@/layouts/Banner.vue";
    import MainContainer from "@/layouts/MainContainer.vue";
    import ContentLayout from "@/layouts/ContentLayout.vue";
    import HousesSelection from "@/layouts/HousesSelection.vue";

    export default {
        name: "MainPage",
        components: {
            CardHouse,
            ContentLayout,
            GridLayout,
            Banner,
            MainContainer,
            HousesSelection,
            SortButton,
            Button,
            MapSvg
        }
    }
</script>

<style lang="scss" scoped>
  .main__container{
    padding: 43px 0 387px;

    @media all and (max-width: 768px) {
        padding: 29px 0 48px;
    }
  }

  .main__selection{
    padding:  0 26px;
  }

  .main__sort{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 47px;

    @media all and (max-width: 768px) {
        display: none;
    }
  }
</style>