<template>
	<a href="#/page1">Page1</a> | 
	<a href="#/page2">Page2</a> | 
	<a href="#/page3">Page3</a> | 
	<a href="#/page4">Page4</a> | 
	<a href="#/page5">Page5</a> | 
	<a href="#/page6">Page6</a>
	<hr>
	<router-view></router-view>
</template>

<!-- script>
  import MainPage from "./pages/MainPage.vue"; // Новостройки - Список 
  // import HouseMapPage from './pages/HouseMapPage.vue'; // Новостройки - Карта 
  //import ShowHousePage from './pages/ShowHousePage.vue'; // Frame Новостройка
  // import ShowApartmentsPage from './pages/ShowApartmentsPage.vue'; // Frame Квартира
  // import ApartmentsPage from "./pages/ApartmentsPage.vue"; // Frame Квартиры (1) 
  // import AllApartmentsPage from "./pages/AllApartmentsPage.vue"; // Frame Квартиры (2) 

  export default {
    name: 'App',
    components: {
        MainPage,
        // ApartmentsPage,
        // AllApartmentsPage,
        // ShowApartmentsPage,
        // ShowHousePage,
        // HouseMapPage
    }
  }
</!-->

<style>
	@import url("./style/_normalize.scss");
</style>
