<template>
    <div class="under-header-block">
        <div>
            <p class="under-header-block__label">Цена застройщика</p>
            <p class="under-header-block__text">3 500 000 р.</p>
        </div>
        <button class="under-header-block__button">
            Позвонить
        </button>
    </div>
</template>
<script>
    export default {

    }
</script>
<style lang="scss">
    @import "@/style/variables";

    .under-header-block{
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__label, &__button{
            line-height: 140%;
        }

        &__label{
            font-size: 14px;
            color: $text-secondary;
        }

        &__text{
            font-size: 20px;
            color: $text-primary;
            font-weight: 500;
        }

        &__button{
            background-color: $text-positive;
            color: white;
            padding: 7px 44px;
            border-radius: 11px;
            font-size: 16px;
        }
    }
</style>