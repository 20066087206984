<template>
    <div class="card-apartments">
        <div class="card-apartments__image-block">
            <img
                class="card-apartments__avatar" 
                src="https://img.dmclk.ru/c960x640q80/vitrina/0e/5e/0e5eb2cced29f53f467025ecc4dd4ceecd6a806e.png"
                alt="apartaments"
            />
            <SliderSwitch gray/>
        </div>
        <div class="card-apartments__information">
            <div class="card-apartments__top">
                <div class="card-apartments__header">
                    <div>
                        <p class="card-apartments__title">Квартира 38 кв.м</p>
                        <p class="card-apartments__sub-title card-apartments__header-bottom">Сдача в IV квартале 2022</p>
                    </div>
                    <div>
                        <p class="card-apartments__title">5 000 000 р.</p>
                        <p class="card-apartments__sub-title card-apartments__header-bottom">250 000 за м2</p>
                    </div>
                </div>
                <div class="card-apartments__tag-list">
                    <Tag>Скидка 10%</Tag>
                    <Tag>0Р оформление</Tag>
                </div>
            </div>
            <div class="card-apartments__characteristic">
                <div class="card-apartments__characteristic-columns">
                    <p class="card-apartments__sub-title">Этаж</p>
                    <p class="card-apartments__value">5</p>
                    <p class="card-apartments__sub-title">Кухня</p>
                    <p class="card-apartments__value">38м2 (Кухня 12м2)</p>
                    <p class="card-apartments__sub-title">Отделка</p>
                    <p class="card-apartments__value">Черновая</p>
                </div>
                <div class="card-apartments__characteristic-row">
                    <p>Этаж 5</p>
                    <p>Черновая</p>
                    <p>IV кв. 2022</p>
                </div>
            </div>
            <div class="card-apartments__location-info">
                <div class="card-apartments__location">
                    <p class="card-apartments__housing-complex">ЖК “Новая Рига”</p>
                    <p class="card-apartments__subway-station">
                        <MetroSvg/>
                        Филатов луг
                        <span class="card-apartments__travel-time">5 минут</span>
                    </p>
                </div>
                <div class="card-apartments__developer-info">
                    <img
                        class="card-apartments__developer-logo"
                        src="https://s.tmimgcdn.com/scr/1200x750/275500/mountain-logo-vector-template-illustration-12_275507-original.jpg"
                    />
                    <div>
                        <p class="card-apartments__value card-apartments__developer-name">ГК Гранель</p>
                        <p class="card-apartments__housing-complex card-apartments__housing-count">234 объекта</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Tag from './Tag.vue';
    import MetroSvg from '@/components/svg/MetroSvg.vue' 
    import SliderSwitch from "@/components/SliderSwitch.vue"

    export default {
        name: "CardApartments",
        components: {
            Tag,
            MetroSvg,
            SliderSwitch
        },  
        props: {
            
        }
    }

</script>

<style lang="scss">

    @import "@/style/variables.scss";

    .card-apartments {
        display: grid;
        background: #FFFFFF;
        border-radius: 15px;
        padding: 19px 30px 19px 26px;
        width: 100%;

        @media all and (min-width: 768px){
            grid-template-columns: min-content 2.5fr;
            max-height: 288px;
            gap: 30px;
        }

        @media all and (max-width: 768px){
            grid-template-rows: 1fr max-content;
        }

        &__image-block{
            position: relative;
        }

        &__avatar {
            object-fit: cover;
            width: 234px;
            height: 241px;
            border: 1px solid rgba(55, 110, 164, 0.06);
            border-radius: 13px;

            @media all and (max-width: 768px){
                width: 100%;
                object-fit: contain;
            }
        }

        &__information {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 15px;

            @media all and (max-width: 768px){
                gap: 12px;
            }
        }

        &__header{
            @media all and (min-width: 768px){
                margin-bottom: 9px;
            }
        }

        &__header, &__location-info {
            display: flex;
            justify-content: space-between;
        }

        &__location-info{
            @media all and (max-width: 768px){
                flex-direction: column;
                gap: 8px;
            }
        }

        &__header-bottom {
            @media all and (max-width: 768px){
                display: none;
            }
        }

        &__title {
            color: #112132;
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 5px;

            @media all and (max-width: 768px){
                font-size: 20px;
            }
        }

        &__sub-title {
            color: #143352A6;
            font-size: 16px;
        }

        &__tag-list {
            display: flex;
            flex-wrap: wrap;
            gap: 7px;

            @media all and (max-width: 768px){
                display: none;
            }
        }

        &__characteristic-columns{
            display: grid;
            grid-template-columns: min-content 1fr;
            column-gap: 20px;
            row-gap: 7px;

            @media all and (max-width: 768px){
                display: none;
            }
        }

        &__characteristic-row{
            display: none;
            @media all and (max-width: 768px){
                display: flex;
                position: relative;
                gap: 16px;

                & > *:not(:last-child){
                    position: relative;
                    &:after{
                        content: "•";
                        position: absolute;
                        transform: translateX(-50%);
                        right: -12px;
                        top: 0;
                        color: $text-disabled
                    }
                }
            }
        }

        &__housing-complex {
            color: #3C7EFF;
            font-size: 16px;
            letter-spacing: 0.01em;
        }


        &__housing-count {
            @media all and (max-width: 768px){
                display: none;
            }
        }

        &__subway-station{
            display: flex;
            gap: 7px;
        }

        &__travel-time {
            color: #ACB2B8;
        }

        &__developer-info {
            display: flex;
            align-items: center;
            gap: 7px;
        }

        &__developer-logo {
            width: 32px;
            object-fit: cover;
            border: 1px solid rgba(42, 84, 126, 0.16);
            border-radius: 50%;
            height: 32px;

            @media all and (max-width: 768px){
                display: none;
            }
        }

        &__location{
            display: flex;
            flex-direction: column;
            gap: 4px;

            @media all and (max-width: 768px){
                flex-direction: column-reverse;
                gap: 13px;
            }
        }
        
        &__value {
            font-size: 16px;
            color: #112132;
        }

        &__housing-complex, &__developer-name{
            @media all and (max-width: 768px){
                font-size: 16px;
                color: $text-secondary;
            }
        }
    }   
</style>