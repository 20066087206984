<template>
    <div class="apartments-list">
        <SortButton class="apartments-list__sort">
            По цене
        </SortButton>
        <GridLayout 
            :gap="22"
            class="apartments-list__container"
        >
            <FlexLayout :gap="17" :flexDirection="column">
                <CardApartments/>
                <CardApartments/>
                <CardApartments/>
            </FlexLayout>
            <div>
                <CallOrdering/>
            </div>
        </GridLayout>
    </div>
    
</template>
<script>
    import CardApartments from '@/components/CardApartments.vue';
    import CallOrdering from '@/components/CallOrdering.vue';
    import SortButton from '@/components/SortButton.vue';
    import GridLayout from '@/layouts/GridLayout.vue';
    import FlexLayout from '@/layouts/FlexLayout.vue';

    export default {
        name: "ApartmentsList",
        components: {
            CallOrdering,
            FlexLayout,
            CardApartments,
            GridLayout,
            SortButton
        }
          
    }
</script>
<style lang="scss">
    .apartments-list{
        @media all and (min-width: 768px){
            padding-top: 41px;
        }

        &__container{
            padding: 35px 0 144px;

            @media all and (min-width: 1024px){
                grid-template-columns: 1fr 360px;
            }

            @media all and (max-width: 1024px){
                padding: 15px 0 65px;
            }
        }

        &__sort{
            @media all and (max-width: 768px){
                display: none;
            }
        }
    }
</style>