<template>
    <button
        :class="rootClasses"
    >
        <SortSvg/>
        <slot />
  </button>
</template>
<script>
    import SortSvg from "@/components/svg/SortSvg.vue"

    export default {
        name: "SortButton",
        props: {
            themes: String,
            noPadding: Boolean
        },
        components: {
            SortSvg
        },
        data() {

            const themes = {
                "dark": "button_dark",
                "noPadding": "button_no-padding",
                "text": "button_text",
                "green": "button_green"
            } 

            const rootClasses = ["sort-button"]
            this.themes && this.themes.split(" ").forEach(theme => rootClasses.push(themes[theme]))

            return {
                isActive: true,
                rootClasses
            }
        },
    }
</script>
<style lang="scss">
    @import "@/style/variables.scss";

    .sort-button {
        color: black;
        padding: 14px 76px 14px 21px;
        background: $background-primary;
        border-radius: 13px;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 7px;
    }
</style>