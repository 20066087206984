<template lang="">
    <input class="input"/>
</template>
<script>
export default {
    name: "InputComponent",
    props: {

    }
}
</script>
<style lang="scss">

    @import "@/style/_variables.scss";

    .input{
        padding: 14px 20px;
        background-color: $background-primary;
        border-radius: 17px;
        color: $text-tetriary;
        font-size: 16px;

        &::placeholder{
            color: $text-tetriary;
            font-size: 16px;
        }
    }
</style>