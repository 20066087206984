<template>
    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.4109 0.701986C18.1988 0.193557 16.9857 0.000122108 15.7732 0.000122108C11.9242 -0.000190387 8.0755 1.94822 4.2265 1.94822C3.2612 1.94822 2.29653 1.82572 1.33123 1.51947C1.22279 1.4851 1.11405 1.46885 1.0078 1.46885C0.469993 1.46885 0 1.88509 0 2.4629V12.3771C0 12.7718 0.225934 13.1459 0.588741 13.2977C1.80091 13.8065 3.01402 13.9999 4.2265 13.9999C8.0755 13.9999 11.9245 12.0515 15.7735 12.0515C16.7388 12.0515 17.7035 12.174 18.6688 12.4802C18.7772 12.5146 18.886 12.5309 18.9922 12.5309C19.53 12.5309 20 12.1146 20 11.5368V1.62291C19.9997 1.22792 19.7738 0.854171 19.4109 0.701986ZM1.49998 3.13195C2.12872 3.28945 2.78496 3.36851 3.45995 3.41101C3.2762 4.32943 2.46871 5.02161 1.49998 5.02161V3.13195ZM1.49998 12.0381V10.545C2.57402 10.545 3.44307 11.3971 3.49088 12.4637C2.78683 12.4071 2.12872 12.2665 1.49998 12.0381ZM9.99984 9.99997C8.61893 9.99997 7.49988 8.65655 7.49988 7.00001C7.49988 5.34316 8.61924 4.00006 9.99984 4.00006C11.3804 4.00006 12.4998 5.34316 12.4998 7.00001C12.4998 8.65717 11.3801 9.99997 9.99984 9.99997ZM18.4997 10.8681C17.9522 10.7309 17.3838 10.654 16.8022 10.6043C16.9857 9.78934 17.6616 9.1706 18.4997 9.06435V10.8681ZM18.4997 3.48975C17.5344 3.36757 16.7854 2.56164 16.7557 1.56635C17.3663 1.63416 17.946 1.76103 18.4997 1.96197V3.48975Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>
<script>
export default {
    
}
</script>
