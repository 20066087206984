<template lang="">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.100374 12.3879C0.0996504 14.571 0.649741 16.7025 1.69539 18.5811L0 25L6.33482 23.2775C8.08027 24.2646 10.0453 24.7848 12.0452 24.7856H12.0503C18.6358 24.7856 23.9972 19.2276 24 12.3975C24.0011 9.08708 22.7591 5.97459 20.5031 3.63312C18.2468 1.29165 15.2468 0.00142881 12.0503 0C5.4633 0 0.102928 5.55702 0.100173 12.3875M3.87261 18.2574L3.63615 17.8679C2.64178 16.2285 2.11697 14.3337 2.1177 12.3884C2.11987 6.71136 6.57525 2.09236 12.0538 2.09236C14.7068 2.09343 17.2002 3.166 19.0758 5.11201C20.9511 7.05817 21.983 9.64529 21.9823 12.3965C21.9799 18.0739 17.5244 22.6932 12.05 22.6932H12.0461C10.2636 22.6921 8.51549 22.196 6.99094 21.2578L6.6281 21.0347L2.86874 22.0569L3.87261 18.2574ZM12.0503 24.7856H12.0504H12.0503C12.0501 24.7856 12.05 24.7856 12.0503 24.7856Z" fill="#173B5E" fill-opacity="0.35"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.05092 7.51104C8.82971 7.02505 8.59696 7.01532 8.38656 7.00677C8.21438 6.99953 8.01745 7.00001 7.82075 7.00001C7.62381 7.00001 7.30395 7.07304 7.03347 7.36507C6.76275 7.65711 6 8.36303 6 9.79874C6 11.2346 7.05813 12.622 7.20554 12.8168C7.35322 13.0114 9.24813 16.0519 12.2489 17.2216C14.743 18.1936 15.2506 18.0003 15.7919 17.9516C16.3332 17.903 17.5388 17.2459 17.7847 16.5645C18.0308 15.8831 18.0308 15.2991 17.957 15.1771C17.8832 15.0555 17.6863 14.9824 17.3911 14.8366C17.0957 14.6907 15.6442 13.9846 15.3736 13.8872C15.1029 13.79 14.9061 13.7414 14.7091 14.0335C14.5123 14.3253 13.9469 14.9825 13.7745 15.1771C13.6024 15.3721 13.4301 15.3965 13.1348 15.2505C12.8395 15.1041 11.8885 14.7962 10.7605 13.8022C9.88274 13.0287 9.29022 12.0735 9.11791 11.7813C8.94572 11.4895 9.09952 11.3315 9.24756 11.186C9.38022 11.0552 9.54291 10.8452 9.69059 10.6749C9.8379 10.5045 9.88705 10.3829 9.98546 10.1882C10.084 9.99345 10.0347 9.82303 9.96094 9.67706C9.88705 9.5311 9.31328 8.088 9.05074 7.51095" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>

<script>
    export default {
        name: "WhatsappSvg"
    }
</script>