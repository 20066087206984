<template>
    <div class="similar-apartments-list">
        <div class="similar-apartments-list__container">
            <p class="similar-apartments-list__title">Ближайшие жилые комплексы</p>
            <div class="similar-apartments-list__wrapper">
                <SimilarApartments />
                <SimilarApartments />
                <SimilarApartments />
                <SimilarApartments />
                <SimilarApartments />
            </div>
        </div>
    </div>
</template>

<script>
    import SimilarApartments from '@/components/SimilarApartments.vue';

    export default {
        name: "SimilarApartmentsList",
        components: {
            SimilarApartments
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/style/variables";
    .similar-apartments-list{
        background-color: $background-primary;
        overflow: hidden;
        padding: 46px 0 90px 20px;

        &__container{
            margin: 0 auto 0 auto;
            max-width: 1200px;
        }

        &__title{
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 49px;

            @media all and (max-width: 768px) {
                font-size: 24px;
            }
        }

        &__wrapper{
            
            display: flex;
            gap: 23px;
        }
    }
</style>