<template>
    <SelectionLayout class="buildings-selection">
        <FlexLayout 
            :gap="18.5" 
            flexDirection="column"
        >
            <Text class="buildings-selection__title">{{ title }}</Text>
            <FlexLayout 
                :gap="12"
                :flexible="true"
            >
                <FieldHandler
                    label="Объект"
                >
                    <Select 
                        :options=[1,2,3,4] 
                        default="Новая Рига"
                    >
                        <HousesSvg/>
                    </Select>
                </FieldHandler>
                <FieldHandler
                    label="Количество комнат"
                >
                    <GridLayout 
                        :gap="5"
                        gridTemplateColumns="max-content 1fr 1fr 1fr"
                    >
                        <Checkbox>
                            Студия
                        </Checkbox>
                        <Checkbox>
                            1
                        </Checkbox>
                        <Checkbox>
                            2
                        </Checkbox>
                        <Checkbox>
                            3+
                        </Checkbox>
                    </GridLayout>
                </FieldHandler>
                <FieldHandler
                    label="Площадь"
                >
                    <Select :options=[1,2,3,4]>
                        <RulerSvg/>
                    </Select>
                </FieldHandler>
                <FieldHandler
                    label="Цена"
                >
                    <Select :options=[1,2,3,4]>
                        <BillSvg/>
                    </Select>
                </FieldHandler>
                <FieldHandler
                    label="Срок сдачи"
                >
                    <Select :options=[1,2,3,4]>
                        <BillSvg/>
                    </Select>
                </FieldHandler>
                <FieldHandler
                    label="Срок сдачи"
                >
                    <Select 
                        :options=[1,2,3,4] 
                        default="Застройщик"
                    >
                        <BillSvg/>
                    </Select>
                </FieldHandler>
            </FlexLayout>
            <FlexLayout justifyContent="space-between" alignItems="center">
                <DropList />
                <Button themes="text">Сбросить фильтр</Button>
            </FlexLayout>
        </FlexLayout>
    </SelectionLayout>
</template>

<script>
    import GridLayout from "@/layouts/GridLayout.vue";
    import Text from "@/components/Text.vue";
    import FieldHandler from "@/components/FieldHandler.vue";
    import Select from "@/components/Select.vue";
    import Checkbox from "@/components/Checkbox.vue";
    import SelectionLayout from "@/layouts/SelectionLayout.vue"
    import FlexLayout from "@/layouts/FlexLayout.vue"
    import DropList from "@/components/DropList.vue"
    import Button from "@/components/Button.vue";

    import HousesSvg from "@/components/svg/HousesSvg.vue"
    import RulerSvg from "@/components/svg/RulerSvg.vue"
    import BillSvg from "@/components/svg/BillSvg.vue"

    export default {
        name: "BuildingsSelection",
        components: {
            GridLayout,
            Select,
            Text,
            Button,
            DropList,
            FlexLayout,
            FieldHandler,
            Checkbox,
            SelectionLayout,
            HousesSvg,
            RulerSvg,
            BillSvg
        },
        props: {
            title: String
        },
    }
</script>

<style lang="scss">
    .buildings-selection{
        padding: 49px 0 56px;

        @media all and (max-width: 768px) {
            padding: 27px 0 16px;
        }

        &__title{
            font-size: 44px;
            
            @media all and (max-width: 768px) {
                font-size: 34px;
            }
        }
    }
</style>
