<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
        <div class="custom-select__selected" :class="{ open: open }" @click="open = !open">
          <slot/>
          {{ selected }}
        </div>
        <div class="items" :class="{ selectHide: !open }">
          <div
            v-for="(option, i) of options"
            :key="i"
            @click="
              selected = option;
              open = false;
              $emit('input', option);
            "
          >
            {{ option }}
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: "SelectCustom",
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: "Выбрать",
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    placeholder: String
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style scoped lang="scss">

@import "@/style/variables.scss";

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  height: 47px;
  line-height: 47px;
  
  &__selected {
    background-color: $background-primary;
    color: #112132;
    border-radius: 15px;
    padding-left: 16px;
    padding-right: 69px;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 7px;

    &:after {
      position: absolute;
      content: "";
      top: 44%;
      right: 21px;
      width: 7px;
      height: 7px;
      border-left: 2px solid $background-tetriary;
      border-bottom: 2px solid $background-tetriary;
      transition: .3s linear;
      transform: rotate(-45deg);
    }
  }
}

.custom-select .open {
  border-radius: 6px 6px 0px 0px;

  &:after {
    transform: rotate(-225deg);
  }
}


.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  position: absolute;
  background-color: $background-primary;
  opacity: 1;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #112132;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #ad8225;
}

.selectHide {
  display: none;
}
</style>