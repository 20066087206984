<template>
    <SelectionLayout class="houses-selection">
        <FlexLayout 
            :gap="18.5" 
            flexDirection="column"
        >
            <Text class="houses-selection__title">{{ title }}</Text>
            <FlexLayout 
                :gap="12"
                :flexible="true"
            >
                <FieldHandler
                    label="Город"
                >
                    <Select 
                        :options=[1,2,3,4] 
                        default="Москва и область"
                    >
                        <HousesSvg/>
                    </Select>
                </FieldHandler>
                <FieldHandler
                    label="Количество комнат"
                >
                    <GridLayout 
                        :gap="5"
                        gridAutoFlow="column"
                        gridAutoColumns="1fr"
                    >
                        <Checkbox>
                            Студия
                        </Checkbox>
                        <Checkbox>
                            1
                        </Checkbox>
                        <Checkbox>
                            2
                        </Checkbox>
                        <Checkbox
                            class="houses-selection__checkbox-optional"
                            :class="{'houses-selection__checkbox_hidden': truncated}"
                        >
                            3
                        </Checkbox>
                        <Checkbox
                            class="houses-selection__checkbox-optional"
                            :class="{'houses-selection__checkbox_hidden': truncated}"
                        >
                            4
                        </Checkbox>
                        <Checkbox 
                            class="houses-selection__checkbox-truncated"
                            :class="{'houses-selection__checkbox-visible': truncated}"
                        >
                            3+
                        </Checkbox>
                    </GridLayout>
                </FieldHandler>
                <FieldHandler
                    label="Цена"
                >
                    <Select :options=[1,2,3,4]>
                        <CoinsSvg/>
                    </Select>
                </FieldHandler>
                <FieldHandler
                    label="Площадь"
                >
                    <Select :options=[1,2,3,4]>
                        <RulerSvg/>
                    </Select>
                </FieldHandler>
                <FieldHandler
                    label="Срок сдачи"
                >
                    <Select :options=[1,2,3,4]>
                        <CalendarSvg/>
                    </Select>
                </FieldHandler>
            </FlexLayout>
            <FlexLayout justifyContent="space-between" alignItems="center">
                <DropList />
                <Button themes="text">Сбросить фильтр</Button>
            </FlexLayout>
        </FlexLayout>
    </SelectionLayout>
</template>

<script>

    import Select from "@/components/Select.vue";
    import SelectionLayout from "@/layouts/SelectionLayout.vue"
    import FlexLayout from "@/layouts/FlexLayout.vue"
    import GridLayout from "@/layouts/GridLayout.vue";
    import Text from "@/components/Text.vue";
    import Checkbox from "@/components/Checkbox.vue";
    import FieldHandler from "@/components/FieldHandler.vue";
    import DropList from "@/components/DropList.vue"
    import Button from "@/components/Button.vue";


    import CoinsSvg from "@/components/svg/CoinsSvg.vue"
    import RulerSvg from "@/components/svg/RulerSvg.vue"
    import CalendarSvg from "@/components/svg/CalendarSvg.vue"
    import HousesSvg from "@/components/svg/HousesSvg.vue"

    export default {
        name: "HousesSelection",
        components: {
            SelectionLayout,
            FlexLayout,
            GridLayout,
            Text,
            FieldHandler,
            Select,
            CoinsSvg,
            RulerSvg,
            CalendarSvg,
            Checkbox,
            DropList,
            Button,
            HousesSvg
        },
        props: {
            title: String,
            truncated: Boolean
        }
    }
</script>

<style lang="scss">
    .houses-selection{
        padding: 49px 0 56px;

        @media all and (max-width: 768px) {
            padding: 27px 0 16px;
        }

        &__title{
            font-size: 44px;
            
            @media all and (max-width: 768px) {
                font-size: 34px;
            }
        }

        &__checkbox-truncated {
            @media all and (min-width: 768px) {
                display: none;
            }
        }

        &__checkbox-optional {
            @media all and (max-width: 768px) {
                display: none;
            }
        }

        &__checkbox_hidden {
            display: none;
        }

        &__checkbox-visible {
            display: block;
        }
    }
</style>