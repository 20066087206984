<template>
    <div class="location">
        <p class="location__title">Расположение</p>
        <div class="location__description">
            <p class="location__description-text">Москва, проспект Магеллана, ЖК “Саларьево парк”, 49, секция 6, корпус 2</p>
            <div class="location__metro">
                <p><MetroSvg class="location__svg-metro"/>Филатов луг <span class="location__travel-time">5 минут</span></p>
                <p><MetroSvg theme="yellow" class="location__svg-metro"/>Прокшино <span class="location__travel-time">10 минут</span></p>
            </div>
        </div>
        <img class="location__map" src="@/assets/img/map2.png" alt="mini-map">
    </div>
</template>
<script>
    import MetroSvg from "@/components/svg/MetroSvg.vue"

    export default {
        name: "LocationComponent",
        components: {
            MetroSvg
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/style/variables.scss';
    .location{
        &__title{
            margin-bottom: 11px;
            font-size: 24px;
            font-weight: 600;
        }

        &__description{
            font-size: 18px;
            margin-bottom: 38px;
        }

        &__description-text{
            color: $text-secondary;
            margin-bottom: 20px;
        }

        &__map{
            width: 100%;
            max-height: 300px;
            object-fit: cover;
        }

        &__metro{
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        &__svg-metro{
            margin-right: 7px;
        }

        &__travel-time {
            color: #ACB2B8;
            margin-left: 9px;
        }
    }
</style>