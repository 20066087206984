<template>
    <div class="album">
        <div class="album__main">
            <div class="album__pagination album__left-arrow-container">
                <ArrowRightSvg class="album__left-arrow"/>
            </div>
            <img
                class="album__main-photo"
                src="@/assets/img/main_house.png"
                alt="main photo"
            />
            <div class="album__pagination album__right-arrow-container">
                <ArrowRightSvg/>
            </div>
            <div class="album__photo-list">
                <img 
                    class="album__photo-item album__photo-item_active"
                    src="@/assets/img/house_1.png"
                    alt="photo item"
                />
                <img 
                    class="album__photo-item"
                    src="@/assets/img/house_2.png"
                    alt="photo item"
                />
                <img 
                    class="album__photo-item"
                    src="@/assets/img/house_3.png"
                    alt="photo item"
                />
                <img 
                    class="album__photo-item"
                    src="@/assets/img/house_1.png"
                    alt="photo item"
                />
                <img 
                    class="album__photo-item"
                    src="@/assets/img/house_2.png"
                    alt="photo item"
                />
                <img 
                    class="album__photo-item"
                    src="@/assets/img/house_3.png"
                    alt="photo item"
                />
                <div
                    class="album__photo-item-wrapper"
                >
                    <img 
                        class="album__photo-item"
                        src="@/assets/img/house_1.png"
                        alt="photo item"
                    />
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
    import ArrowRightSvg from '@/components/svg/ArrowRightSvg.vue'

    export default {
        name: "AlbumCustom",
        components: {
            ArrowRightSvg
        }
    }
</script>

<style lang="scss">
    
    .album {
        width: 100%;
        margin-bottom: 85px;

        @media all and (max-width: 768px) {
            margin-bottom: 15px;
        }

        &__main {
            position: relative;
        }

        &__main-photo {
            height: 260px;
            width: 100%;
            object-fit: cover;

            @media all and (min-width: 768px) {
                border-radius: 30px;
                height: 355px;
            }
        }

        &__pagination {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            position: absolute;
            width: 60px;
            height: 31px;
            background: rgba(255, 255, 255, 0.7);
            backdrop-filter: blur(2px);
            border-radius: 40px;
            padding: 0 12px;

            @media all and (max-width: 768px) {
                display: none;
            }
        }

        &__left-arrow-container {
            top: 50%;
            left: 25px;
        }

        &__right-arrow-container {
            flex-direction: row-reverse;
            top: 50%;
            right: 25px;
        }

        &__left-arrow{
            transform: rotate(180deg)
        }

        &__photo-list {
            display: flex;
            gap: 8.6px;
            position: absolute;
            bottom: -40px;
            left: 27px;

            @media all and (max-width: 768px) {
                display: none;
            }
        }

        &__photo-item {
            width: 80px;
            border-radius: 23.674px;
            height: 84px;
            border: 3px solid #ffffff;
            object-fit: cover;
            position: relative;

            &_active {
                border: 3px solid #009B47;
            }

            
        }

        &__photo-item-wrapper{
            position: relative;
            
            &::before{
                content: "+36";
                position: absolute;
                width: 40px;
                height: 40px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: white;
                border-radius: 50%;
                z-index: 200;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #1122338C;
                font-weight: 600;
            }
        }
    }

</style>