<template >
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0.5L4 3.5L7 0.5" stroke="#112233"/>
    </svg>
</template>

<script>
    export default {
        name:  "ArrowDownSvg",
    }
</script>

<style lang="">
    
</style>