<template>
    <div class="apartments-table">
        <div class="apartments-table__selection">
            <HousesTableSelection/>
        </div>
        <div class="apartments-table__variant_table">
            <div class="apartments-table__container">
                <div class="apartments-table__list">
                    <div class="apartments-table__list-header">
                        <p class="apartments-table__list-title">Квартира (34)</p>
                        <p class="apartments-table__list-title">Срок сдачи</p>
                        <p class="apartments-table__list-title">Общая (Кухня)</p>
                        <p class="apartments-table__list-title">Стоимость</p>
                    </div>
                    <div 
                        class="apartments-table__list-content"
                        
                    >
                        <div 
                            v-for="(apartment, i) of data"
                            :key="i"
                            class="apartments-table__list-row"
                            :class="{'apartments-table__list-row_selected': apartment?.id === selected?.id}"
                            @click="
                                selected = apartment
                            "
                        >
                            <p class="apartments-table__list-text apartments-table__list-text_green">{{ apartment.square }}</p>
                            <p class="apartments-table__list-text">{{ apartment.date }}</p>
                            <p class="apartments-table__list-text">{{ apartment.general }}</p>
                            <p class="apartments-table__list-text">{{ apartment.cost }}</p>
                        </div>
                    </div>
                </div>
                <div class="apartments-table__view">
                    <img
                        class="apartments-table__view-image"
                        :src="selected.src"
                        alt="selected-apartment"
                    />
                </div>
            </div>
            <Button themes="text green" class="apartments-table__button">
                Все квартиры в {{ complex }}
            </Button>
        </div>
        <div class="apartments-table__variant_rows">
            <p class="apartments-table__title">Квартиры</p>
            <div class="apartments-table__rows">
                <div class="apartments-table__row">
                    <div>
                        <p class="apartments-table__row-title">Студии</p>
                        <div class="apartments-table__row-params">
                            <span class="apartments-table__row-subtitle">14.2 млн - 16.3 млн</span>
                            <div class="apartments-table__row-separator"/>
                            <span class="apartments-table__row-subtitle">36.2 - 42.5 м2</span>
                        </div>
                    </div>
                    <div class="apartments-table__aparts-count">
                        <p>24</p>
                        <ArrowRightSvg/>
                    </div>
                </div>
                <div class="apartments-table__row">
                    <div>
                        <p class="apartments-table__row-title">1 комната</p>
                        <div class="apartments-table__row-params">
                            <span class="apartments-table__row-subtitle">14.2 млн - 16.3 млн</span>
                            <div class="apartments-table__row-separator"/>
                            <span class="apartments-table__row-subtitle">36.2 - 42.5 м2</span>
                        </div>
                    </div>
                    <div class="apartments-table__aparts-count">
                        <p>24</p>
                        <ArrowRightSvg/>
                    </div>
                </div>
                <div class="apartments-table__row">
                    <div>
                        <p class="apartments-table__row-title">2 комнаты</p>
                        <div class="apartments-table__row-params">
                            <span class="apartments-table__row-subtitle">14.2 млн - 16.3 млн</span>
                            <div class="apartments-table__row-separator"/>
                            <span class="apartments-table__row-subtitle">36.2 - 42.5 м2</span>
                        </div>
                    </div>
                    <div class="apartments-table__aparts-count">
                        <p>24</p>
                        <ArrowRightSvg/>
                    </div>
                </div>
                <div class="apartments-table__row">
                    <div>
                        <p class="apartments-table__row-title">Студии</p>
                        <div class="apartments-table__row-params">
                            <span class="apartments-table__row-subtitle">14.2 млн - 16.3 млн</span>
                            <div class="apartments-table__row-separator"/>
                            <span class="apartments-table__row-subtitle">36.2 - 42.5 м2</span>
                        </div>
                    </div>
                    <div class="apartments-table__aparts-count">
                        <p>24</p>
                        <ArrowRightSvg/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import Button from "@/components/Button.vue"
    import HousesTableSelection from '@/layouts/HousesTableSelection.vue';
    import ArrowRightSvg from '@/components/svg/ArrowRightSvg.vue';

    const data = [
        {   
            id: 1,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/3918732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
        {
            id: 2,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://d33wubrfki0l68.cloudfront.net/97f337956b87f4589dbf68591f22f5f3dacf2736/55f2a/img/redux_white.svg"
        },
        {
            id: 3,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/d8f6b39f-2563-ec11-8121-005056b8d207.svg"
        },
        {
            id: 4,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/ca16732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
        {
            id: 5,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/c514732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
        {
            id: 6,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/3918732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
        {
            id: 7,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/3918732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
        {
            id: 8,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/3918732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
        {
            id: 9,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/3918732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
        {
            id: 10,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/3918732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
        {
            id: 11,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/3918732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
        {
            id: 12,
            square: "1 комн, 38 м2",
            date: "I кв. 2022",
            general: "34.5 м (11м2)",
            cost: "14 000 000 р.",
            src: "https://media.samolet.ru/flat/plan/3918732a-f8e6-ec11-b822-005056b8a6c4.svg"
        },
    ]
    export default {
        name: "ApartmentsTable",
        components: {
            Button,
            HousesTableSelection,
            ArrowRightSvg
        },
        props: {
            complex: String
        },
        data(){
            return {
                selected: data?.[0],
                data
            }
        }
    }
</script>
<style lang="scss">
    @import "@/style/variables.scss";

    .apartments-table{
        padding: 0px 22px;
        margin-bottom: 70px;
        margin-top: 24px;

        @media all and (max-width: 768px) {
            margin-bottom: 52px;
        }

        &__container{
            gap: 60px;
            display: flex;
            flex-wrap: wrap;

            @media all and (max-width: 1024px) {
                flex-direction: column;
            }
        }

        &__selection{
            padding: 0 0 24px 0;
            max-width: 880px;

            @media all and (max-width: 768px) {
                display: none;
            }
        }
        
        &__variant_table{
            @media all and (max-width: 768px) {
                display: none;
            }
        }

        &__variant_rows{
            @media all and (min-width: 768px) {
                display: none;
            }
        }

        &__list{
            flex: 2;
        }

        &__list-header{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            padding: 14px 40px 14px 18px;
            gap: 30px;
        }

        &__list-title{
            color: $text-tetriary;
            font-size: 16px;
        }

        &__list-content{
            overflow-y: auto;
            max-height: 400px;
            padding-right: 13px;

            &::-webkit-scrollbar{
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: $background-tetriary;
                border-radius: 13px;
                
            }

        }

        &__list-row{
            display: grid;
            padding: 14px 18px;
            grid-template-columns: repeat(4, 1fr);
            gap: 30px;
            cursor: pointer;
            border-radius: 10px;
            position: relative;

            &:hover{
                background-color: $background-positive;
            }

            &:not(:last-child){
                &:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    left: 0;
                    bottom: 0;
                    background-color: $border-primary;

                }

            }

            &_selected{
                background-color: $background-positive;
            }
        }

        &__list-text{
            color: $text-primary;
            font-size: 16px;

            &_green{
                color: $text-positive;
            }
        }

        &__view{
            height: 400px;
            margin-top: 42px;

            @media all and (min-width: 1025px) {
                flex: 1.5;
            }
        }

        &__view-image{
            min-width: 100%;
            height: 80%;
            max-width: 480px;
            object-fit: contain;
        }

        &__button{
            margin-top: 25px;
        }

        &__title{
            font-size: 24px;
            font-weight: 600;
            color: $text-primary;

        }

        &__rows{
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
        }

        &__row{
            display: grid;
            grid-template-columns: 1fr max-content;
            align-items: center;
        }

        &__aparts-count{
            color: $text-positive;
            display: flex;
            gap: 15px;

        }

        &__row-title{
            color: $text-primary;
            font-size: 16px;
            line-height: 140%;
        }

        &__row-subtitle{
            color: $text-secondary;
            line-height: 140%;

        }

        &__row-params{
            display: flex;
            align-items: center;
            gap: 8px;

        }

        &__row-separator{
            width: 4px;
            height: 4px;
            background: $text-tetriary;
            transform: translateY(-100%);
        }

    }
</style>