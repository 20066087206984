<template>
    <div class="similar-apartments">
        <div class="similar-apartments__image">
            <img
                 class="similar-apartments__avatar"
                 src="https://th.bing.com/th/id/R.da6af4d20558a114afaf9c3c9cad194d?rik=bdRGGGsUCkHvgw&pid=ImgRaw&r=0"
            />
            <SliderSwitch gray/>
        </div>
        <div class="similar-apartments__content">
            <div class="similar-apartments__content-header">
                <p class="similar-apartments__title">Квартира 38 кв.м</p>
                <p class="similar-apartments__title">5.2 млн. р.</p>
            </div>
            <div class="similar-apartments__characteristic">
                <p>Этаж 5</p>
                <p>Черновая</p>
                <p>IV кв.  2022</p>
            </div>
            <p class="similar-apartments__location">
                <MetroSvg/>
                Филатов луг
                <span>5 минут</span>
            </p>
            <p class="similar-apartments__name">ЖК “Новая Рига”</p>
            <p class="similar-apartments__name">Застрощик Гранель</p>
        </div>
    </div>
</template>

<script>

    import MetroSvg from "@/components/svg/MetroSvg.vue";
    import SliderSwitch from "@/components/SliderSwitch.vue"

    export default {
        name: "SimilarApartments",
        components: {
            MetroSvg,
            SliderSwitch
        }
    }
</script>

<style lang="scss" scoped>
    .similar-apartments {
        width: 100%;
        height: max-content;
        padding: 11px 15px;
        background: #FFFFFF;
        border-radius: 17px;
        min-width: 327px;

        &__slider {
            background-color: rgba(23, 59, 94, 0.35);
            left: 30%;
            bottom: 17px;
            &, &::before, &::after {
                position: absolute;
                width: 27px;
                height: 4px;
                border-radius: 6px;
                content: "";
            }
            &::before {
                left: 35px;
                bottom: 0;
                background-color: rgba(42, 84, 126, 0.13);
            }
            &::after {
                left: 70px;
                bottom: 0;
                background-color: rgba(42, 84, 126, 0.13);
            }
        }

        &__image, &__avatar {
            width: 100%;
            height: 100%;
        }
        &__image {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(55, 110, 164, 0.06);
            border-radius: 13px; 
            overflow: hidden;
        }
        &__avatar {
            object-fit: cover;
        }

        &__content {
            display: flex;
            margin-top: 13px;
            flex-direction: column;
            gap: 6px;
        }

        &__content-header {
            display: flex;
            justify-content: space-between;

        }

        &__title {
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            /* identical to box height, or 28px */
            letter-spacing: 0.01em;
            /* Text/Primary */
            color: #112132;
        }

        &__characteristic {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
            p:nth-child(2) {
                position: relative;
                width: max-content;
                &::before, &::after {
                    content: "";
                    position: absolute;
                    border-radius: 50%;
                    background: rgba(23, 59, 94, 0.35);
                    width: 5px;
                    height: 5px;
                    top: 3px;
                }
                &::after{
                    left: -10px;
                }
                &::before {
                    right: -10px;
                }
            }
        }

        &__location {
            color: #112233;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.01em;
            display: flex;
            gap: 7px;
            span {
                color: #ACB2B8;
            }
        }

        &__name {
            color: rgba(20, 51, 82, 0.65);
            font-size: 16px;
            line-height: 140%;  
            letter-spacing: 0.01em;
        }
    }
</style>