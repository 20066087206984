<template>
    <div class="heading">
        <div 
            class="heading__header"
            :class="{heading__header_mini: mini}"
        >
            <div class="heading__wrapper">
                <p class="heading__title" :class="{heading__title_mini: mini}">
                    {{ title }}
                </p>
                <div class="heading__toggle-list" v-if="toggleList">
                    <!-- <p class="heading__toggle-item heading__toggle-item_active">Описание</p>
                    <p class="heading__toggle-item">Подробные характеристики</p> -->

                    <p 
                        class="heading__toggle-item"
                        v-for="(option, i) of options"
                        :key="i"
                        :class="{'heading__toggle-item_active': option.selected }"
                    >
                        {{ option.text }}
                    </p>
                </div>
            </div>
            <div class="heading__button-right">
                <slot name="button"/>
            </div>
        </div>
        <slot />
        <div class="heading__open-button-wrapper">
            <slot name="open-button"/>
        </div>
    </div>    
</template>

<script>
    export default {
        name: "HeadingCustom",
        props: {
            toggleList: {
                type: Boolean,
                default: false,
            },
            title: String,
            mini: Boolean,
            options: Array
        }
    }
</script>

<style lang="scss">
    .heading {
        &__title {
            color: #112233;
            font-weight: 600;
            font-size: 36px;

            @media all and (max-width: 768px) {
                font-size: 24px;
            }

            &_mini{
                font-size: 28px;
            }
        }

        &__wrapper{
            width: 100%;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 50px;

            &_mini{
                margin-bottom: 16px;
            }
        }
        &__toggle-list {
            display: flex;
            gap: 33px;
            margin-top: 15px;
            overflow-x: scroll;

            &::-webkit-scrollbar{
                display: none;
            }
        }
        &__toggle-item {
            font-size: 18px;
            letter-spacing: 0.04em;
            color: rgba(17, 34, 51, 0.35);
            font-weight: 500;
            white-space: nowrap;
            &_active {
                color: #009B47;
                border-bottom: 1px solid #009B47;
            }
        }

        &__open-button-wrapper{
            @media all and (max-width: 1024px) {
                display: none;
            }
        }

        &__button-right{
            @media all and (max-width: 1024px) {
                display: none;
            }
        }
    }
</style>