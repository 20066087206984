<template>
    <svg width="7" height="11" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M1.29006 0.710022C0.900059 1.10002 0.900059 1.73002 1.29006 2.12002L5.17006 6.00002L1.29006 9.88002C0.900059 10.27 0.900059 10.9 1.29006 11.29C1.68006 11.68 2.31006 11.68 2.70006 11.29L7.29006 6.70002C7.68006 6.31002 7.68006 5.68002 7.29006 5.29002L2.70006 0.700022C2.32006 0.320022 1.68006 0.320022 1.29006 0.710022Z" 
            :fill='currentTheme'
            :fill-opacity="opacity"
        />
    </svg>
</template>
<script>
    const themes = {
        default: "#173B5E",
        green: "#009B47",
    }
    
    export default {
        props: {
            theme: {
                type: String,
                required: false,
                default: "default"
            },
            opacity: {
                type: String,
                required: false,
                default: "0.35"
            }
        },
        data(){
            return {
                currentTheme: themes[this.theme]
            }
        },
    }
</script>
