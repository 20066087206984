<template>
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0.111084C7.58368 0.111084 5.625 2.06976 5.625 4.48608C5.625 6.43956 8.48438 9.99997 9.57986 11.2923C9.80174 11.5541 10.1986 11.5541 10.4201 11.2923C11.5156 9.99997 14.375 6.43956 14.375 4.48608C14.375 2.06976 12.4163 0.111084 10 0.111084ZM0.698611 7.60935C0.492423 7.69182 0.31567 7.83417 0.191149 8.01804C0.066628 8.20191 4.93822e-05 8.41888 0 8.64095L0 17.3326C0 17.7257 0.396875 17.9944 0.761806 17.8486L5.55556 15.6666V7.57358C5.24861 7.01872 4.99757 6.47845 4.81771 5.96178L0.698611 7.60935ZM10 12.5996C9.51146 12.5996 9.04931 12.385 8.73229 12.0107C8.04965 11.2052 7.32361 10.2878 6.66667 9.34685V15.6663L13.3333 17.8885V9.3472C12.6764 10.2878 11.9507 11.2055 11.2677 12.0111C10.9507 12.385 10.4885 12.5996 10 12.5996ZM19.2382 5.70692L14.4444 7.88886V17.8889L19.3014 15.9462C19.5076 15.8637 19.6844 15.7214 19.8089 15.5375C19.9334 15.3536 20 15.1366 20 14.9146V6.22289C20 5.82983 19.6031 5.56108 19.2382 5.70692Z" fill="#173B5E" fill-opacity="0.35"/>
    </svg>
</template>
<script>
    export default {
        
    }
</script>
